import { h, Component } from 'preact';
import { Router} from 'preact-router';
import { useReducer} from 'preact/hooks';
import { useEffect, useState, useRef } from 'preact/hooks';
import { Match } from 'preact-router/src/match';
import { route } from 'preact-router';

import NetworkInformationApiPolyfill from '../../node_modules/network-information-api-polyfill/src';
import convertVapidKey from 'convert-vapid-public-key';

import { getItem, setItem, removeAll } from '../lib/myStore';
import CONSTANTS from '../lib/constants';
import axios from 'axios';
import { unProtectedRoutes } from '../lib/routes';

import LeftInfoPanel from '../components/leftInfoPanel';
import RightPanel from '../components/rightPanel';
import SlowInternetSpeed from '../components/slowInternet';
import BrowserNotSupported from '../routes/pages/browserNotSupport';
import NoInternet from '../components/noInternet';
import { RightInfoPanel } from '../components/rightInfoPanel';
import Createvisitmodal from "../components/createvisitmodal";
import CountryCode from "../components/countryCode";
import Login from '../routes/login';
import WorkspaceV4 from '../routes/workspaceV4';
import SetNewPassword from '../routes/setNewPassword';
import SetPasswordToVerify from '../routes/setPasswordToVerify';
import Profile from '../routes/profile';
import SiloAdministration from '../routes/siloAdministration';
import Organization from '../routes/organization';
import ProcessView from '../routes/processView';
import CaseManagementV4 from '../routes/caseManagementV4';
import ProcessMapping from '../routes/processMapping';
import Reports from '../routes/reports';
import Widgets from '../routes/widgets';
import ProcessMappingNoStage from '../routes/processMappingNoStage';
import WorkspaceApprovalDetailsV2 from '../routes/workspaceApprovalDetailsV2';
import WorkspaceMyApprovalDetails from '../routes/workspaceMyApprovalDetails';
import WorkspaceCouponDetails from '../routes/workspaceCouponDetails';
import WorkspacePolicyCouponDetails from '../routes/workspacePolicyCouponDetails';
import ContextOne from '../components/context';
import {caseDetailsReducer, initialState} from '../components/reducerStore';
import Search from '../routes/search';
/*
    Modification:- Search by Vendor in Global search. Create a new search functionality. Do not use the one used for Cases (T178)
    Modified by:- Rajkumar Pradhan
    Modified on:- 20/02/2024
*/
import AdminSearch from '../routes/adminSearch';
import Analytics from '../routes/analytics';
import WorkspaceListV3 from '../routes/workspaceListV3';
import NotificationDashboard from '../routes/notificationDashboard';
import EntityFormMapping from "../routes/entityFormMapping";
import ThirdPartyVendor from "../routes/thirdPartyVendor";
import Request from "../routes/request";
import Catalogue from "../routes/catalogue";
import ThirdPartyDetails from "../routes/thirdPartyDetails";
import PurchaseOrder from "../routes/purchaseOrder";
import PurchaseOrderDetails from "../routes/purchaseOrderDetails";
import RequestItems from '../routes/requestItem';
import AdminEvent from '../routes/adminEvent';
import Facility from '../routes/facility';
import FacilityRoom from '../routes/facilityRoom';
import FormViewer from '../routes/formViewer';
import AnalyticsList from '../routes/analyticsList';
import WorkspaceInventoryDetails from '../routes/workspaceInventoryDetails';
import WorkspacePODetails from '../routes/workspacePODetails';
import WorkspaceMRNDetails from '../routes/workspaceMRNDetails';
import AdminRequest from "../routes/adminRequest";
import InventoryHistory from "../routes/inventoryHistory";
import Support from '../routes/support';
import EliminationDashboard from '../routes/eliminationDashboard';
import AssetsDetails from '../routes/assetsDetails';
import AdminRoute from "../routes/adminRoute";
/*
  Modification:- New silo admin dashboard Imported
  By:- Rajkumar Pradhan
  On:- 03-04-2024
*/
import SiloAdmin from "../routes/siloAdmin";
import Inventory from "../routes/inventory";
import RequestManagement from "../routes/requestManagement";
import Vendor from "../routes/vendor";
import AssetRegister from '../routes/assetRegister';
import DynamicPropConfig from '../routes/dynamicPropertiesConfigurator';
import NetworkIssue from '../routes/pages/networkIssue';
import DataStatusDashboard from  '../routes/dataStatusDashboard';
import CaseStatus from '../routes/caseStatus';
/*
  Modification:- New scheduler dashboard Imported
  By:- Arun Singh
  On:-26-04-2024
*/
import SchedulerDashboard from '../routes/schedulerDashboard';
import PurchaseOrderDashboard from '../routes/purchaseOrderDashboard';
import EventDetails from '../routes/eventDetails';
import PaymentPendingRequest from '../routes/paymentPendingRequest';
import AuditTaskChecklist from '../routes/auditTaskChecklist';

const App = () => {
// export default class App extends Component {
/** Gets fired when the route changes.
 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
 *	@param {string} event.url	The newly routed URL
 */

  const [state, dispatch] = useReducer(caseDetailsReducer, initialState);
  const [isBrowserNotSupported, setIsBrowserNotSupported] = useState(false);
  const [isLeftNavExpanded, setIsLeftNavExpanded] = useState(false);
  const [isOuterDivNeeded, setIsOuterDivNeeded] = useState(false);
  const [isSlowInternetSpeed, setIsSlowInternetSpeed] = useState(false);
  const [slowInternet, setSlowInternet] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.screen.availWidth <= 425 ? true : false);
  let [isUserInfoUpdated, setIsUserInfoUpdated] = useState(false);
  let [isOngoingCallWindowOpen,setOngoingCallWindowOpen] = useState(false);
  let [trueCallerData, setTrueCallerData]= useState({});
  let [currentInteraction, setCurrentInteraction]= useState({});
  let [showLeftPanel, setShowLeftPanel] = useState(false);
  let [showMobileFooter, setShowMobileFooter] = useState(true);
  let [goingUp, setGoingUp] = useState(true);
  const prevScrollY = useRef(-1);
  const [isNetworkIssue, setIsNetworkIssue] = useState(false);

  /*
    Bug: COMP_BG_714 Sales Accounts Executive Mobile View - Honda / Kia - New Login Screen and Dashboard	If the user has closed the active application from the active application list and reopened it again, the login screen is displayed for a few seconds.
    Modification: Added function to determine to go to either workspace or root page based on existence of token
    By: Devang
    Date: 26/12/2023
  */
  useEffect(() => {
    const token = getItem('token');
    if (token && Object.keys(token).length) {
      /*
        Modification: T330 - Taking Authentication changed from KDMS - Updated condition value for includes operation
        By: Devang
        Date: 30/05/2024
      */
      if (unProtectedRoutes.includes(location.pathname)) {
        /*
          Bug: 7COMP_BG_73 All Roles Mobile View - Honda / Kia - New , Renewal Mobile Back Button - Login Page	If the user is logged in and clicked on the back button of the mobile , the login page for few seconds
          Modification: Replaced login url with workspace so if user tries to go back, he will still land on workspace page
          By: Devang
          Date: 27/12/2023
        */
        window.history.replaceState({}, document.title, '/workspace');
        route('/workspace');
      }
    } else {
      /*
        Bug: 7COMP_BG_73 All Roles Mobile View - Honda / Kia - New , Renewal Mobile Back Button - Login Page	If the user is logged in and clicked on the back button of the mobile , the login page for few seconds
        Modification: Added condition so if user lands on / page, it won't be redirected to same page again
        By: Devang
        Date: 27/12/2023
      */
      /*
        Bug: T216 - BG_FN - After clicking on forgot password link , the page is getting auto refreshed. (Showstopper)
        Modification: Added set-new-password and set-password page to restrict from routing to / page
        By: Devang
        Date: 19/04/2024
      */
      /*
        Modification: T330 - Taking Authentication changed from KDMS - Updated condition value for includes operation
        By: Devang
        Date: 30/05/2024
      */
      if (!unProtectedRoutes.includes(location.pathname)) {
        route('/');
      }
    }
  });

  useEffect(() => {
    let connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection;
    console.log(connection);
    let type;
    if (connection) {
      type = connection.effectiveType;
      if (parseFloat(connection.downlink) <= 0.6) {
        setIsSlowInternetSpeed(true);
        console.log("slow internet", isSlowInternetSpeed);
      } else {
        setIsSlowInternetSpeed(false);
      }
      if (connection.type === 'none' || getItem('isTimedout') === true) {
        setIsNetworkIssue(true);
        console.log('connection.type 1: ', connection.type);
      } else {
        setIsNetworkIssue(false);
      }
      console.log('connection type 1: ', connection.type);
    } else {
      new NetworkInformationApiPolyfill().then(async (connection) => {
        if (parseFloat(connection.downlink) <= 0.6){
          setSlowInternet(true);
        }
        if (parseFloat(connection.downlink) > 0.6){
          setSlowInternet(false);
        }
        if (connection.type === 'none' || getItem('isTimedout') === true) {
          console.log('connection.type 2: ', connection.type);
          setIsNetworkIssue(true);
        } else {
          setIsNetworkIssue(false);
        }
        console.log('connection type 2: ', connection.type);
      });
    }

    function updateConnectionStatus() {
      if (connection) {
        type = connection.effectiveType;
        if (parseFloat(connection.downlink) <= 0.6) {
          setIsSlowInternetSpeed(true);
          // that.setState({isSlowInternetSpeed: true});
        } else {
          setIsSlowInternetSpeed(false);
          // that.setState({isSlowInternetSpeed: false});
        }
      } else {
        new NetworkInformationApiPolyfill().then(async (connection) => {
          if (parseFloat(connection.downlink) <= 0.6){
            setSlowInternet(true);
            // await this.setState({slowInternet:true});
          }
          if (parseFloat(connection.downlink) > 0.6){
            setSlowInternet(false);
            // await this.setState({slowInternet:false});
          }
        });
      }
    }

    if (connection) {
      connection.addEventListener('change', updateConnectionStatus);
    }
    let isOnline = window.navigator.onLine;
    if (isOnline) {
      setIsOffline(false);
    } else {
      setIsOffline(true);
      // this.setState({ isOffline: true });
    }
    window.addEventListener('offline', () => {
      setIsOffline(true);
      setIsSlowInternetSpeed(false);
      // this.setState({ isOffline: true, isSlowInternetSpeed:false });
      return;
    });
    window.addEventListener('online', () => {
      setIsOffline(false);
      // window.location.reload();
    });
    setServiceWorkerForNotifications();
  },[]);
  useEffect(() => {
    let browserResult = getBrowserVersion();
    console.log("browserResult",browserResult);
    // need to add userAgent value for MS Edge
    if (browserResult === "Other") {
      setIsBrowserNotSupported(false);
      console.log(isBrowserNotSupported);
      // this.setState({isBrowserNotSupported:false});
    } else if ((browserResult === "Edge") || (browserResult >= 9 && browserResult < 10) || (browserResult >= 11 && browserResult < 12)) {
      setIsBrowserNotSupported(true);
    }
  }, []);

  useEffect( () => {
    if (window.screen.availWidth >= 425) {
      setIsMobileView(false);
    } else {
      setIsMobileView(true);
    }
  },[window.screen.availWidth]);

  async function setServiceWorkerForNotifications() {
    let token = getItem('token');
    console.log('Token Found');
    if (Object.keys(token).length) {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        const localStorage = getItem('subscribe').isSubscribed;
        if (!localStorage) {
          console.log('!localStorage');
          if ('Notification' in window) {
            if ('serviceWorker' in navigator) {
              // Register a service worker hosted at the root of the
              // site using the default scope.
              await navigator.serviceWorker.register('/sw.js').then(async (registration) => {
                console.log('Service worker registration succeeded:', registration);
                await Notification.requestPermission(async (status) => {
                  if (status === 'granted') {
                    subscribeUser();
                  }
                });
              }, /*catch*/ (error) => {
                console.error(`Service worker registration failed: ${error}`);
              });
            } else {
              console.error('Service workers are not supported.');
            }
          }
        }
      }
      if (!isMobile) {
        const localStorage = '';
        // getItem('subscribe').isSubscribed;
        if (!localStorage) {
          if ('Notification' in window) {
            console.log('!localStorage desktop');
            if ('serviceWorker' in navigator) {
              // Register a service worker hosted at the root of the
              // site using the default scope.
              await navigator.serviceWorker.register('/sw.js').then(async (registration) => {
                console.log('Service worker registration succeeded:', registration);
                await Notification.requestPermission(async (status) => {
                  if (status === 'granted') {
                    subscribeUser();
                  }
                });
              }, /*catch*/ (error) => {
                console.error(`Service worker registration failed: ${error}`);
              });
            } else {
              console.error('Service workers are not supported.');
            }
          }
        }
      }
    }
  }
  function subscribeUser() {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.pushManager.getSubscription()
          .then(pushSubscription => {
            if (!pushSubscription) {
              //the user was never subscribed
              subscribe(registration);
            } else {
              //check if user was subscribed with a different key
              let json = pushSubscription.toJSON();
              let public_key = json.keys.p256dh;

              console.log(public_key);

              if (public_key != CONSTANTS.publicVapidKey) {
                pushSubscription.unsubscribe().then(successful => {
                  // You've successfully unsubscribed
                  subscribe(registration);
                }).catch(e => {
                  // Unsubscription failed
                  console.log('Unsubscription failed!');
                });
              }
            }
          });
      });
  }

  async function subscribe(registration) {
    await navigator.serviceWorker.getRegistration().then(async reg => {
      console.log(reg, 'Registration!');
      const subscription = await reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertVapidKey(CONSTANTS.publicVapidKey)
      });
      let result = await axios.put(`${CONSTANTS.API_URL}/api/v1/savePushSubscription`,{pushSubscriptionForMobile: subscription});
      console.log(result, 'Subscription Saved', subscription);
      if (result.data.result === 'success') {
        await setItem('subscribe', {isSubscribed: true});
        // await stopLoader();
      }
    });
  }

  function getBrowserVersion() {
    let detectIEregexp, isEdge;
    console.log("navigator.userAgent",navigator.userAgent);
    if (navigator.userAgent.indexOf('MSIE') !== -1) {
      detectIEregexp = /MSIE (\d+\.\d+);/; //test for MSIE x.x
    }
    else if (navigator.userAgent.indexOf('Edg') !== -1) {
      detectIEregexp = /Edg/;
      isEdge = true;
    }
    else {
      detectIEregexp = /Trident.*rv[ :]*(\d+\.\d+)/; //test for rv:x.x or rv x.x where Trident string exists
    } // if no "MSIE" string in userAgent
    console.log("detectIEregexp.test(navigator.userAgent)",detectIEregexp.test(navigator.userAgent));
    if (detectIEregexp.test(navigator.userAgent)){ //if some form of IE
      if (isEdge) {
        return 'Edge';
      }
      let ieversion=new Number(RegExp.$1); // capture x.x portion and store as a number
      if (ieversion>=11 && ieversion<12)
        return (ieversion);
      else if (ieversion>=9 && ieversion<10)
        return (ieversion);

    } else {
      return ("Other");
    }
  }

  function updateUserInfo() {
    setIsUserInfoUpdated(!isUserInfoUpdated);
  }

  async function toggleOnGoingCallWindow() {
    let TruecallerData = getItem('trueCallerData');
    let currentRowID = getItem('currentRowID');
    await setTrueCallerData(TruecallerData);
    await setCurrentInteraction(currentRowID);
    await setOngoingCallWindowOpen(!isOngoingCallWindowOpen);
  }


  function toggleLeftInfoPanel(e) {
    setShowLeftPanel(prev => !prev);
  }

  function handleScroll(event){
    const currentScrollY = event.currentTarget.scrollTop;
    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false);
      setShowMobileFooter(false);
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true);
      setShowMobileFooter(true);
    }
    prevScrollY.current = currentScrollY;
  }

  return (
    <div class={`${!isMobileView ? 'display-flex' : ''} notranslate `} translate="no">
      <div >
        <Match path="/">
          {
            ({ path }) => {
              console.log(path, 'path');
              /*
                Modification: T330 - Taking Authentication changed from KDMS - T1654 - Added condition for nonprotected routes
                By: Devang
                Date: 30/05/2024
              */
              // if (path !== '/' && path !== '/kia' && path !== '/readEmails' && path !== '/set-new-password' && path !== '/set-password' && path !== '/parineetihonda' && path !== '/kotharihyundai' && path !== '/kia'){
              if (!unProtectedRoutes.includes(path)) {
                return (
                  <div>
                    <LeftInfoPanel setShowMobileFooter={setShowMobileFooter} showLeftPanel={showLeftPanel} setShowLeftPanel={setShowLeftPanel} isUserInfoUpdated={isUserInfoUpdated} />
                  </div>
                );
              }
            }
          }
        </Match>
      </div>
      {
        !isBrowserNotSupported && !isNetworkIssue && (
          <div id="app">
            <meta name="google" content="notranslate" />
            <link rel="manifest" href="/manifest.json" />
            <link rel="icon" type="image/x-icon" href="./assets/icons/dos-32x32.ico" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            {/*<link rel="stylesheet" href="/assets/js/mobiscrollDesktop.css" />
            <link href="/assets/js/mobiscroll.javascript.min.css" rel="stylesheet" />*/}
            <link rel="stylesheet" href="/assets/js/toastr.css" />
            <link rel="stylesheet" href="/assets/dist/jsonview.bundle.css" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
            <link href="https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet"/>
            {/*<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.97.7/css/materialize.min.css"/>*/}
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <script src='https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.68/vfs_fonts.js' />
            <script src='https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.68/pdfmake.js' />
            <script src='/assets/js/toastr.min.js' />
            <script src='/assets/dist/jsonview.bundle.js' />
            {/*<script type="text/javascript" src="/assets/js/live-agent.js" />*/}

            <Match path="/">
              {
                ({ path }) => {
                  /*
                    Modification: T330 - Taking Authentication changed from KDMS - T1654 - Added condition for nonprotected routes
                    By: Devang
                    Date: 30/05/2024
                  */
                  // if (path !== '/' && path !== '/readEmails' && path !== '/set-new-password' && path !== '/set-password' && path !== '/parineetihonda' && path !== '/kotharihyundai' && path !== '/kia' ){
                  if (!unProtectedRoutes.includes(path)) {
                    return (
                      <div>
                        {
                          isOffline && (
                            <NoInternet />
                          )
                        }
                        {
                          isSlowInternetSpeed && (
                            <SlowInternetSpeed />
                          )
                        }
                      </div>
                    );
                  }
                }
              }
            </Match>
            <div>
              <ContextOne.Provider value={[state,dispatch]}>
                <Router>
                  <Login path="/:tenant?" isMobileView={isMobileView} />
                  {/*<Workspace path="/workspace" updateUserInfo={() => updateUserInfo()} />*/}
                  <WorkspaceV4 path="/workspace" updateUserInfo={() => updateUserInfo()} toggleLeftInfoPanel={toggleLeftInfoPanel} showMobileFooter={showMobileFooter} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  {/*<WorkspaceV4 path="/workspace" updateUserInfo={() => updateUserInfo()} toggleLeftInfoPanel={toggleLeftInfoPanel} showMobileFooter={showMobileFooter} handleScroll={handleScroll} />*/}
                  <SetNewPassword path="/set-new-password" isMobileView={isMobileView}/>
                  <SetPasswordToVerify path="/set-password" />
                  <Profile path="/profile" />
                  <SiloAdministration path="/siloAdministration" />
                  <Organization path="/organization" />
                  {/*
                    Modification: T360 - Commented CaseDetail, TaskView, Workspacedetails & CaseView as not needed
                    By: Devang
                    Date: 13/06/2024
                  */}
                  {/*<CaseDetail path="/caseDetails/:id" />
                  <CaseView path="/caseView/:id"/>
                  <TaskView path="/taskView/:id" />
                  <Workspacedetails path="/workspacedetails/:type" isMobileView={isMobileView} showMobileFooter={showMobileFooter} setShowMobileFooter={setShowMobileFooter} handleScroll={handleScroll} toggleLeftInfoPanel={toggleLeftInfoPanel}
                    isOngoingCallWindowOpen={isOngoingCallWindowOpen} trueCallerData={trueCallerData} currentInteraction={currentInteraction} updateUserInfo={() => updateUserInfo()}
                  />*/}
                  {/*
                    Modified By: Arun Singh
                    Modification: Pass setShowMobileFooter function as a props to handle state of showMobileFooter .
                    Date: 29/09/2023
                  */}
                  <WorkspaceListV3 path="/workspaceList/:api?/:typeOfData?" isMobileView={isMobileView} setShowMobileFooter={setShowMobileFooter} showMobileFooter={showMobileFooter} onGoingCallAction={(e)=> toggleOnGoingCallWindow(e)} handleScroll={handleScroll}/>
                  <ProcessView path="/processView/:id" onGoingCallAction={(e)=> toggleOnGoingCallWindow(e)} isMobileView={isMobileView}/>
                  {/*
                    Modification: T360 - Commented WorkspaceCaseDetailsV2 as not needed
                    By: Devang
                    Date: 13/06/2024
                  */}
                  {/*<WorkspaceCaseDetailsV2 path="/workspaceCaseDetails" isMobileView={isMobileView} showMobileFooter={showMobileFooter} setShowMobileFooter={setShowMobileFooter} handleScroll={handleScroll} onGoingCallAction={(e)=> toggleOnGoingCallWindow(e)}
                    toggleLeftInfoPanel={toggleLeftInfoPanel} updateUserInfo={() => updateUserInfo()}
                  />*/}
                  {/*
                    Bug: Feature - Info Modal Value Change Approval
                    Modification: Added new approval route with new route component
                    By: Devang
                    Date: 30/01/2023
                  */}
                  <WorkspaceApprovalDetailsV2 path="/workspaceApprovalDetails" isMobileView={isMobileView} showMobileFooter={showMobileFooter} setShowMobileFooter={setShowMobileFooter} handleScroll={handleScroll} toggleLeftInfoPanel={toggleLeftInfoPanel}
                    isOngoingCallWindowOpen={isOngoingCallWindowOpen} trueCallerData={trueCallerData} currentInteraction={currentInteraction} updateUserInfo={() => updateUserInfo()}
                  />
                  {/*
                    Modification: Added a new route for my approvalDate
                    By: Rajkumar Pradhan
                    Date: 09-08-2023
                    */}
                  <WorkspaceMyApprovalDetails path="/workspaceMyApprovalDetails" isMobileView={isMobileView} showMobileFooter={showMobileFooter} setShowMobileFooter={setShowMobileFooter} handleScroll={handleScroll} toggleLeftInfoPanel={toggleLeftInfoPanel}
                    isOngoingCallWindowOpen={isOngoingCallWindowOpen} trueCallerData={trueCallerData} currentInteraction={currentInteraction} updateUserInfo={() => updateUserInfo()}
                  />
                  {/*
                    Modification: Added route for searchpage
                    By: Devang
                    Date: 22/02/2023
                  */}
                  <Search path="/search" updateUserInfo={() => updateUserInfo()} />
                  {/*
                    Modification:- Search by Vendor in Global search. Create a new search functionality. Do not use the one used for Cases (T178)
                    Modified by:- Rajkumar Pradhan
                    Modified on:- 20/02/2024
                  */}
                  <AdminSearch path="/adminSearch" updateUserInfo={() => updateUserInfo()} />
                  {/*
                    Modification: Added route for NotificationDashboard
                    By: Komal
                    Date: 29/03/2023
                  */}
                  <NotificationDashboard path="/notificationDashboard" />
                  {/*
                    Bug: Feature - Dedicated Coupons Page
                    Modification: Added new coupon route with new route component
                    By: Devang
                    Date: 08/02/2023
                  */}
                  {/*
                    Modification: Add updateUserInfo function to update userInfo
                    By: Komal
                    Date: 20/02/2023
                  */}
                  <WorkspaceCouponDetails path="/workspaceCouponDetails" updateUserInfo={() => updateUserInfo()}/>
                  {/*
                    Modification:- WorkspacePolicyCouponDetails applied to routes
                    Name:- Rajkumar Pradhan
                    Date:- 30-08-2023
                  */}
                  <WorkspacePolicyCouponDetails path="/workspacePolicyCouponDetails" updateUserInfo={() => updateUserInfo()}/>

                  {/*
                    Modification: Created, Imported and added CaseManagement to the routes
                    By: Devang
                    Date: 18/06/2022
                  */}
                  {/*
                    Bug: CaseManagement View Upgrade
                    Modification: Commented CaseManagement route and replaced with CaseManagementV2
                    By: Devang
                    Date: 03/02/2023
                  */}
                  {/*
                    Bug: CaseManagement View Upgrade
                    Modification: Commented CaseManagementV2 route and replaced with CaseManagementV3
                    By: Devang
                    Date: 29/03/2023
                  */}
                  {/*
                    Bug: CaseManagement View Upgrade
                    Modification: Replaced CaseManagementV3 with CaseManagementV4
                    By: Devang
                    Date: 09/06/2023
                  */}
                  {/*<CaseManagement path="/caseManagement"/>*/}
                  <CaseManagementV4 path="/caseManagement" updateUserInfo={() => updateUserInfo()}/>

                  {/*
                  Modification: Created, Imported and added ProcessMapping to the routes
                  By: Devang
                  Date: 02/08/2022
                */}
                  <ProcessMapping path="/processMapping"/>
                  {/*
                  Modification: Created, Imported and added Reports to the routes
                  By: Komal Wable
                  Date: 21/12/2022
                */}
                  <Reports path="/reports/:type?" updateUserInfo={() => updateUserInfo()}/>
                  {/*
                  Modification: added widget path
                  By: Prasannadatta
                  Date: 24/07/2022
                */}
                  <Widgets path="/widgets"/>
                  <ProcessMappingNoStage path="/processMappingNoStage" />
                  <Analytics path="/analytics" updateUserInfo={() => updateUserInfo()} />
                  <AnalyticsList path="/analyticsList/:typeOfAnalytics/:typeOfData" updateUserInfo={() => updateUserInfo()} onGoingCallAction={(e)=> toggleOnGoingCallWindow(e)} />
                  {/*
                  Modification: Created, Imported and added EntityFormMapping, ThirdPartyVendor, Request and Catalogue to the routes and add handleScroll, isMobileView prop
                  By: Masum Raja
                  Date: 11/05/2023
                */}
                  <EntityFormMapping path="/entityFormMapping" />
                  <ThirdPartyVendor path="/thirdParty" updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <ThirdPartyDetails path="/thirdParty/:id" updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <PurchaseOrder path="/purchaseOrder" updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <PurchaseOrderDetails path="/purchaseOrder/:id" updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  {/*
                    Modification: New Event page for admin
                    By: Rajkumar Pradhan
                    On: 26 Aug 2024
                  */}
                  <EventDetails path="/event/:id" updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <Request path="/request" updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <Catalogue path="/catalogue" updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <RequestItems path='/requestItem/:typeOfData?' updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <AdminEvent path='/adminEvent' updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <PaymentPendingRequest path='/paymentPendingRequest' updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <Facility path='/facility' updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <FacilityRoom path='facilityRoom' updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <FormViewer path="/formViewer" updateUserInfo={() => updateUserInfo()}/>
                  <WorkspaceInventoryDetails path="/workspaceInventoryDetails" updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <WorkspacePODetails path="/workspacePODetails" updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  {/*
                    modified : Ashutosh G
                    modified : 18/12/2023
                    modification : WorkspaceMRNDetails added
                  */}
                  <WorkspaceMRNDetails path="/workspaceMRNDetails" updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <AdminRequest path="/adminRequest" updateUserInfo={() => updateUserInfo()}/>
                  {/*
                    By: Prasannadatta Kawadkar
                    on: 31st August 2023
                    Modification: created route for Inventory History
                  */}
                  <InventoryHistory path="/inventoryHistory" updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView}/>
                  <Support path="/support" updateUserInfo={() => updateUserInfo()}/>
                  {/*
                    Moidification:- New admin dashboard created
                    By:- Rajkumar Pradhan
                    On:- 19-10-2023
                    Moidification:- Added new route for assets details page
                    Name:- Rajkumar Pradhan
                    On:- 19-10-2023
                  */}
                  <AssetsDetails path="/assetsDetails/:id" isMobileView={isMobileView} handleScroll={handleScroll} />
                  <AdminRoute path="/adminRoute" isMobileView={isMobileView} handleScroll={handleScroll}/>
                  {/*
                    Moidification:- New silo admin dashboard created
                    By:- Rajkumar Pradhan
                    On:- 03-04-2024
                  */}
                  <SiloAdmin path="/siloAdmin" isMobileView={isMobileView} handleScroll={handleScroll}/>
                  <Inventory path="/inventory" isMobileView={isMobileView} handleScroll={handleScroll}/>
                  <RequestManagement path="/requestManagement" isMobileView={isMobileView} handleScroll={handleScroll}/>
                  <Vendor path="/vendor" isMobileView={isMobileView} handleScroll={handleScroll}/>
                  <PurchaseOrderDashboard path="/purchaseOrderDashboard" isMobileView={isMobileView} handleScroll={handleScroll}/>
                  <AssetRegister path="/assets" updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView} />
                  <DynamicPropConfig path='/dynamicPropConfig' updateUserInfo={() => updateUserInfo()} handleScroll={handleScroll} isMobileView={isMobileView} />
                  <EliminationDashboard path="/eliminationDashboard" updateUserInfo={() => updateUserInfo()} isMobileView={isMobileView} handleScroll={handleScroll} />
                  <DataStatusDashboard path="/dataStatusDashboard" isMobileView={isMobileView} setShowMobileFooter={setShowMobileFooter} handleScroll={handleScroll} updateUserInfo={() => updateUserInfo()} />
                  <CaseStatus path="/caseStatus/:status?" isMobileView={isMobileView} handleScroll={handleScroll} setShowMobileFooter={setShowMobileFooter} updateUserInfo={() => updateUserInfo()} />
                  {/*
                  Modification: Created, Imported and added SchedulerDashboard route
                  By: Arun Singh
                  Date: 26/04/2024
                */}
                  <SchedulerDashboard path="/schedulerDashboard" updateUserInfo={() => updateUserInfo()} isMobileView={isMobileView} handleScroll={handleScroll}/>
                  {/*
                    Modification:- Audit Task Checklist List
                    By:- Rajkumar Pradhan
                    On:- 16-11-2024
                  */}
                  <AuditTaskChecklist path='/auditTaskChecklist' updateUserInfo={() => updateUserInfo()} isMobileView={isMobileView} handleScroll={handleScroll}/>
                </Router>
              </ContextOne.Provider>
            </div>
          </div>
        )}
      {
        isBrowserNotSupported && (
          <div>
            <BrowserNotSupported path="/browserNotSupported" />
          </div>
        )
      }
      {
        isNetworkIssue && (
          <div>
            <NetworkIssue path="/networkIssue" />
          </div>
        )
      }
      <div>
        <Match path="/">
          {
            ({ path }) => {
              /*
                Modification: T330 - Taking Authentication changed from KDMS - T1654 - Added condition for nonprotected routes
                By: Devang
                Date: 30/05/2024
              */
              // if (path !== '/' && path !== '/readEmails' && path !== '/set-new-password' && path !== '/set-password' && path !== '/kia'){
              if (!unProtectedRoutes.includes(path)) {
                return (
                  <div>
                    <RightPanel
                      isMobileView={isMobileView}
                      showMobileFooter={showMobileFooter}
                      isOngoingCallWindowOpen={isOngoingCallWindowOpen}
                      trueCallerData={trueCallerData}
                      currentInteraction={currentInteraction}
                    />
                  </div>
                );
              }
            }
          }
        </Match>
      </div>
    </div>
  );
  // }
};
export default App;
