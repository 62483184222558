import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { setItem, getItem } from '../../lib/myStore';
import { formatDateTime, getFormattedAmount, inWords, getCompressedImageFile, deepCopy } from '../../lib/utils';
import LinearProgress from 'preact-material-components/LinearProgress';
import { NewPopupModal, NewPopupModalBody } from '../../components/newPopupModal';
import ListSingleCard from '../../components/listSingleCard';
import FileViewer from '../../components/fileViewer';
import moment from 'moment';
import axios from 'axios';
import CONSTANTS from '../../lib/constants';
import pincodeDirectory from 'india-pincode-lookup';
import { State, City }  from 'country-state-city';
import toastr from "toastr";
import PoOrderBox from "../../components/poOrderBox";
import InventoryAvailableBox from "../../components/inventoryAvailableBox";
import EmployeeDebitNoteV2 from "../../components/employeeDebitNoteV2";
import CustomizeSelect from "../../components/customizeSelect";
import { callAPI, reportException } from '../../lib/transceiver';

import { QuestionMark } from '../../components/svgs';

/**
 * Bug: CDMS_ADMIN_FIXES_51 Bug Functional	Admin tab - Request tile - Acknowledge Request form - REQUESTED FOR - Department , Employee - SELECTION FOR - Dropdown list	Dropdownlist is incorrect for the mentioned scenario
 * Modification: Added mapper select
 * A functional component to render and handle select tag those who are MapperSelect(s) and select formType.
 * The component is designed to get and set its enum values based on its dependentDynamicProp change.
 * @component
 */
const MapperSelect = (props) => {
  const {
    allDynamicProps /** Prop - Arr of all dynamicProps in the form */,
    dynamicProp /** Prop - Represents a single dynamic prop which is responsible for having the information about the input field */,
    entityObject /** Prop - Represents the object which has all the information of the current entity */,
    saveFormInput /** Prop - Represents the function handling the change of value in the select tag */,
    propIndex /** Prop - Index of the traversing through dynamicProps */,
    isDisabled /** Props - Represents if form is editable */
  } = props;

  const dependentProp = dynamicProp.dependentDynamicProp ? allDynamicProps.find(dp => dp.name === dynamicProp.dependentDynamicProp) : null;

  /**
   * Represents list of objects which are shown as enums in the select tag.
   * @type {Object[]}
   */
  const [enumArr, setEnumArr] = useState([]);

  /**
   * Represents if the component enum list retrieval is in process.
   * @type {boolean}
   */
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Executes function to get Enum Array value if
   * either dynamicProp's dependent dynamicProp is updated.
   */
  useEffect(() => {
    getEnumArr();
  }, [dynamicProp.dependentDynamicProp ? entityObject[dynamicProp.dependentDynamicProp] : null].filter(e => e));

  /**
   * Fetches Enum Array for the current dynamicProp
   */
  const getEnumArr = async () => {
    if (dynamicProp.dependentDynamicProp && entityObject[dynamicProp.dependentDynamicProp] || !dynamicProp.dependentDynamicProp) {
      try {
        setEnumArr([]);
        setIsLoading(true);
        let response = await callAPI(`put`, `/v1/entityDynamicEnum/${dynamicProp.uuid}`, entityObject);
        setEnumArr(response.data);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    } else {
      setEnumArr([]);
    }
  };

  return (
    <div class="display-flex flex-direction-column">
      <select disabled={isDisabled} class='select' id={`field-${dynamicProp.name}`} onChange={(e) => saveFormInput(e, dynamicProp.name, "select", 0)} >
        <option value=''>Select {dynamicProp.displayName}</option>
        {
          enumArr.map((enumVal) => (
            <option selected={entityObject[dynamicProp.name] === enumVal.uuid} value={enumVal.uuid}>{enumVal[dynamicProp.enumDisplayKey] || enumVal[`dynamicProperties_${dynamicProp.enumDisplayKey}`]}</option>
          ))
        }
      </select>
      {isLoading && <span class="italic">Options Loading...</span>}
      {dynamicProp.dependentDynamicProp && !entityObject[dynamicProp.dependentDynamicProp] && <span class="italic">Please select {(dependentProp && dependentProp.displayName) || dynamicProp.dependentDynamicProp} to show values here</span>}
    </div>
  )
};

const CoreEntityDynamicForm = (props) => {
  console.log(props,"propspropspropspropspropsoprosp")
  // Main form props data to get from processMapping
  /*
    - formOperation
    - formEntityName
    - formEntityObject
    - setFormEntityObject
    - formDynamicProps
  */
  let {
    entityName,
    operation,
    toggleFormPopover,
    backgroundColor,
    dynamicProps,
    entityObject:entityObjectFromProps,
    resetFormValues,
    isListDataReLoad,
    masterTask,
    typeRequest,
    isMobileView
  } = props;

  const FILENAME = "coreEntityDynamicForm", PAGENAME = "coreEntityDynamicForm";
  const filetypes = ['png', 'jpg', 'jpeg', 'pdf', 'mp3'];
  const imageTypeExtension = ['png', 'jpg', 'jpeg', 'gif'];
  const userInfo = getItem('userinfo');
  let futureDateDisabled = new Date();
  let dd = futureDateDisabled.getDate();
  let mm = futureDateDisabled.getMonth()+1; //January is 0!
  let yyyy = futureDateDisabled.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  futureDateDisabled = yyyy+'-'+mm+'-'+dd;
  let [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
  let [uploadedfile, setUploadedfile] = useState([]);
  let [fileViewerFor, setFileViewerFor] = useState({
    for: 'case'
  });
  let [progress, setProgress] = useState(0);
  let [isFormEditable, setIsFormEditable] = useState(false);
  let [isFormSubmitted, setIsFormSubmitted] = useState(false);
  let [stateList, setStateList] = useState(State.getStatesOfCountry('IN'));
  let [errorFields, setErrorFields] = useState([]);
  let [entityObject, setEntityObject] = useState(entityObjectFromProps);
  let [isFormUpdated, setIsFormUpdated] = useState(false);
  let [isDataSaving, setIsDataSaving] = useState(false);
  const [selectedCatelogueItems, setSelectedCatelogueItems] = useState({ fieldName: "", value: []});
  const [selectedEmployeeDebitNotes, setSelectedEmployeeDebitNotes] = useState({});

  /*
    Bug: CDMS_ADMIN_FIXES_51 Bug Functional	Admin tab - Request tile - Acknowledge Request form - REQUESTED FOR - Department , Employee - SELECTION FOR - Dropdown list	Dropdownlist is incorrect for the mentioned scenario
    Modification: Commented below logic to implement mapperSelect logic
    By: Devang
    Date: 01/02/2024
  */
  /*const [employeeList, setEmployeeList] = useState([]);
  const dBFetchFields = ["dynamicProperties_requestedForSelection"];
  const urlObject = {
    "department": { url: "/v1/getDepartmentList", parameter: `dealershipID=${userInfo.userDealershipID}`},
    "branch": { url: "/v1/getBranchList", parameter: `dealershipID=${userInfo.userDealershipID}` },
    "employee": { url: "/v1/users", parameter: `dealershipIDs=${userInfo.userDealershipID}` }
  };*/
  let [areaList, setAreaList] = useState(props.areaList);
  /*
    modified : Ashutosh G
    modified : 20/12/2023
    modification : inventoryAvailableBoxEditableNames added to makedeepCopy inventoryAvailableBox Editable
  */
  let inventoryAvailableBoxEditableNames = ["dynamicProperties_customCatalogue"];

  useEffect(async () => {
    if (!toggleFormPopover) {
      setIsFormSubmitted(true);
    }
  }, [toggleFormPopover]);

  useEffect(async () => {
    try {
      /*if (actions.length) {
        setActions(actions);
        setSelectedArrayForDynamicProps(actions);
        payload['actionName'] = currentAction ? currentAction.displayName : '';
      }
      setSelectedActionIndex(selectedActionIndex);
      setCurrentTask(currentTask);
      setInteraction(interactionObj);*/
      setIsDataSaving(true);
      let payload = deepCopy(entityObject);
      if (payload.coreEntity === 'adminRequest' && payload.coreEntityProcessName === 'Repair Request Flow') {
        if (payload.displayName === 'Submit Bill') {
          let labourInvoice = payload.labourInvoice ? payload.labourInvoice : 0;
          let partInvoice = payload.partInvoice ? payload.partInvoice : 0;
          let taxInvoice = payload.taxInvoice ? payload.taxInvoice : 0;
          let discount = payload.discountAmount ? payload.discountAmount : 0;
          let totalAmount = (Number(labourInvoice) + Number(partInvoice) + Number(taxInvoice)) - Number(discount);
          // payload.set('totalAmount', totalAmount);
          payload.totalAmount = totalAmount;
          if (totalAmount >= 10000) {
            payload.isGreaterThan10000 = 'true';
          } else {
            payload.isGreaterThan10000 = 'false';
          }
          setEntityObject(payload);
        }
      }
      console.log(payload.totalAmount, 'entityObjectentityObject');

      if (entityObject.allCreatedTasks && entityObject.allCreatedTasks[0] && entityObject.allCreatedTasks[0].uuid) {
        let Response = await axios.put(`${CONSTANTS.API_URL}/api/v1/updateCoreEntityTask`, payload);
        console.log(Response, 'ResponseResponseResponseResponse');
        /*if (actions.length) {
          let actionsArr = actions;
          let newActionArr = Response.data.actions;
          await Promise.all(actionsArr.map(action => {
            let actionObj = newActionArr.find(actionObj => actionObj.displayName === action.displayName);
            action['actionStatus'] = actionObj.status;
            action['isVisited'] = actionObj.isVisited;
          }));
          await setActions(actionsArr);
        }*/
        if (Response.data.errorFields && Response.data.errorFields.length) {
          payload['errorFields'] = Response.data.errorFields;
          payload['isVisited'] = Response.data.isVisited;
          await setErrorFields(Response.data.errorFields);
        } else {
          payload['errorFields'] = [];
          payload['isVisited'] = Response.data.isVisited;
          await setErrorFields([]);
        }
      }
      setIsDataSaving(false);
    } catch (HTTPException) {
      console.log(HTTPException, 'HTTPExceptionHTTPException');
      let payload = {
        apiURL:HTTPException.config.url,
        errorObject:HTTPException,
        statusCode:HTTPException.response.status,
        errorMessage:HTTPException.message,
        functionName:"useEffect",
        fileName:"CoreEntityDynamicForm",
        pageName: "CoreEntityDynamicForm",
        userID: userInfo.uuid,
        userName:userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  }, [isFormUpdated]);

  /*
    modified : Ashutosh G
    modified : 23/10/2023
    modification : using both below useEffect to assigned seleted catelogue items to entityObject
  */
  useEffect(() => {
    if (selectedCatelogueItems.fieldName !== "") {
      let obj = JSON.parse(JSON.stringify(entityObject));
      if (selectedCatelogueItems.value.length !== 0) {
        obj[selectedCatelogueItems.fieldName] = JSON.stringify(selectedCatelogueItems.value);
      } else {
        obj[selectedCatelogueItems.fieldName] = "";
      }
      setEntityObject(obj);
      setIsFormUpdated(!isFormUpdated);
    }
  }, [selectedCatelogueItems]);

  /*
    Bug: CDMS_ADMIN_FIXES_51 Bug Functional	Admin tab - Request tile - Acknowledge Request form - REQUESTED FOR - Department , Employee - SELECTION FOR - Dropdown list	Dropdownlist is incorrect for the mentioned scenario
    Modification: Commented below logic to implement mapperSelect logic
    By: Devang
    Date: 01/02/2024
  */
  /*
    modified : Ashutosh G
    modified : 23/10/2023
    modification : using useEffect to decide to fetch value for custom fields depends upon dBFetchFields
  */
  /*useEffect(async () => {
    let dBFetchFieldsExistsArray = dynamicProps.filter(item => dBFetchFields.includes(item.name)).map(item => item.name);
    if (dBFetchFieldsExistsArray.length > 0) {
      if (dBFetchFieldsExistsArray.includes("dynamicProperties_requestedForSelection")){
        await getRequestedForOptions(entityObject.dynamicProperties_requestedFor);
      }
    }
  }, [entityObject]);*/

  /*
    Modification: Commented function to fix the update issue
    By: Devang
    Date: 23/01/2024
  */
  /*async function changeEmployeeDebitDetails(e, fieldName, id, quantity = 0) {
    if (["e", "E", "+", "-"].includes(quantity)) {
      return;
    }
    quantity = Math.abs(parseInt(quantity));
    let elementType = e.target.type;
    let tempSelectedEmployeeDebitNotes = selectedEmployeeDebitNotes;
    if (!e.target.checked && elementType === "checkbox") {
      delete tempSelectedEmployeeDebitNotes[id];
      setSelectedEmployeeDebitNotes({...tempSelectedEmployeeDebitNotes});
    } else {
      tempSelectedEmployeeDebitNotes[id] = quantity;
      setSelectedEmployeeDebitNotes({...tempSelectedEmployeeDebitNotes});
    }
    entityObject[fieldName] = tempSelectedEmployeeDebitNotes;
    console.log(tempSelectedEmployeeDebitNotes, 'tempSelectedEmployeeDebitNotestempSelectedEmployeeDebitNotestempSelectedEmployeeDebitNotes');
  }*/

  async function getReferenceFilesForFileViewer(e, type, openViewer, fieldName, entityID) {
    try {
      let fileViewerObj = [];
      let fileID = [entityObject[''+fieldName]];
      if (type === 'Uploaded Files') {
        fileViewerObj.push({name: 'Uploaded Files', fileIDs: fileID});
      }
      /*
        Modification: PH_TN_T1439 Updated interactionID to entity as the logic does not align with uploadFile logic to get image form S3 properly
        By: Devang
        Date: 22/01/2024
      */
      let payload = {
        // interactionID: entityObject.type === 'Vendor' ? 'entity' : entityObject.uuid ? entityObject.uuid : 'entity',
        interactionID: 'entity',
        s3KeyFor: 'case',
        fileViewerObj
      };
      fileViewerFor['interactionID'] = entityID;
      let response = await axios.put(`${CONSTANTS.API_URL}/api/fileViewer`, payload);
      fileViewerFor.type = type;
      setFileViewerFor(fileViewerFor);
      if (response && response.data) {
        await setUploadedfile(response.data);
        if (openViewer) await setIsFileViewerOpen(true);
      }
    } catch (HTTPException) {
      console.error(HTTPException);
      let parameter = {
        interactionID: entityObject.uuid,
        s3KeyFor: 'case',
        fileViewerObj
      };
      let payload = {
        apiURL:HTTPException.config.url,
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"getReferenceFilesForFileViewer",
        fileName:"createEditForm",
        pageName: "createEditForm",
        payload: parameter,
        userID: userInfo.uuid,
        userName: userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  }

  async function toggleFileViewer() {
    setIsFileViewerOpen(!isFileViewerOpen);
  }

  async function closeFileViewer() {
    setIsFileViewerOpen(false);
  }

  function getFilteredList(dependentEnum) {
    let filteredList = dependentEnum.filter(dependentEnumObj => {
      let filteredLength = dependentEnumObj.query.filter((d) => {
        return d.valueOfProp === entityObject[d.nameOfProp];
      }).length;
      if (filteredLength === dependentEnumObj.query.length) {
        return dependentEnumObj;
      }
    });
    return filteredList;
  }

  async function toggleEditForm() {
    setIsFormEditable(!isFormEditable);
  }

  const inputClicked = async (e) => {
    e.preventDefault();
    let textBox = document.getElementById(e.target.id);
    if (textBox) {
      await textBox.focus();
    }
  };

  /**
   * Modification: CDMS_ADMIN_FIXES_51
   * RECURSIVE FUNCTION - Finds and reset child entityID of the entityID from the object provided
   * and keeps on doing that until its childEntity has has values or there are child entity of current entity.
   * For ex - change operation on the tenantID, the function will find all the entities whose parent-entity is tenant.
   *
   * @param {Object} obj - The formEntityObject which contains all the information of the current entity.
   * @param {string} fieldName - The fieldName on which change operation is performed.
   * @returns {Object} The Object - formEntityObject after resetting all the consecutive keyIDs.
   */
  const resetDependentProps = (obj, fieldName) => {
    let dependentKeys = [];

    dynamicProps.map(dynamicProp => {
      if (dynamicProp.dependentDynamicProp === fieldName) {
        dependentKeys.push(dynamicProp.name);
      }
    })
    if (dependentKeys.length) {
      dependentKeys.map((dependentKey) => {
        obj[dependentKey] = '';
        obj = resetDependentProps(obj, `${dependentKey}`);
      });
    }
    return obj;
  };

  /*
    Bug: CDMS_ADMIN_FIXES_51 Bug Functional	Admin tab - Request tile - Acknowledge Request form - REQUESTED FOR - Department , Employee - SELECTION FOR - Dropdown list	Dropdownlist is incorrect for the mentioned scenario
    Modification: Added resetDependentProps condition
    By: Devang
    Date: 01/02/2024
  */
  const saveFormInput = async (e, fieldName, formType, index) => {
    e.preventDefault();

    let obj = JSON.parse(JSON.stringify(entityObject));
    if (formType && formType === "select") {
      obj[fieldName] = e.target.value;
      obj = resetDependentProps(obj, fieldName);
    } else {
      obj[fieldName] = e.target.value;
    }
    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  };
  const preSaveFormInput = async () => {
    let obj = JSON.parse(JSON.stringify(entityObject));
    obj["dynamicProperties_typeOfRequest"] = "Repair / Service Request Flow";
    obj = resetDependentProps(obj, "dynamicProperties_typeOfRequest");
    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  }
  const saveFormInputRadioDefault = (e, fieldName, typeOfDBField) => {
    let obj = JSON.parse(JSON.stringify(entityObject));
    if (typeOfDBField === "Boolean") {
      obj[fieldName] = e.target.value.toLowerCase() === 'yes' ? true : false;
    } else {
      obj[fieldName] = e.target.value;
    }
    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  };
  const saveFormInputRadio = (e, fieldName, value, index) => {
    let obj = JSON.parse(JSON.stringify(entityObject));
    obj[fieldName] = e.target.value;
    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  };
  const saveFormInputCheckbox = (e, fieldName, value) => {
    let obj = JSON.parse(JSON.stringify(entityObject));
    if (e.target.checked && (!obj[fieldName] || !obj[fieldName].split(",").includes(value))) {
      obj[fieldName] = obj[fieldName] + ',' + value;
    } else if (!e.target.checked && obj[fieldName].split(",").includes(value)) {
      let idx = obj[fieldName].split(",").findIndex(d => d === value);
      let tempArr = obj[fieldName].split(",");
      let deleted = tempArr.splice(idx,1);
      obj[fieldName] = tempArr.join(",");
    }
    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  };
  const setFormValueInput = async (e, fieldName) => { // input values
    e.preventDefault();
    let obj = JSON.parse(JSON.stringify(entityObject));

    if (!e.target.value) {
      obj[fieldName] = e.target.value;
      let successNode = document.getElementById("success-"+fieldName);
      if (successNode) {
        successNode.style.display = "none";
      }
    } else {
      obj[fieldName] = e.target.value;
    }

    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  };
  const saveAreaFromComponentForm = (e, name) => {
    let obj = JSON.parse(JSON.stringify(entityObject));
    obj[name] = e.target.value;
    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  };
  /*
    Modification: PH_TN_T1439 Accepted approvalStatus in parameter and accordingly updated payload to be send in update/create functions
    By: Devang
    Date: 27/01/2024
  */
  const submitForm = async (e, approvalStatus) => { //On submit event base on edit or create
    e.preventDefault();
    console.log(operation, 'operation');
    let copy = deepCopy(entityObject);
    // if (operation === 'Close Request') {
    //   copy.dynamicProperties_status = 'Closed';
    // } else {
    // }

    try {
      if (operation === "Edit") {
        await updateCoreEntity(copy.uuid, approvalStatus ? {...copy, status: approvalStatus} : copy);
        toggleFormPopover();
        resetFormValues();
      } else {
        // Create Core Entity
        await createCoreEntity(approvalStatus ? {...copy, status: approvalStatus} : copy);
        toggleFormPopover();
      }
      resetFormValues();
      /*
        modified : Ashutosh G
        modified : 20/12/2023
        modification : list data reloaded after submiting form
      */
      isListDataReLoad(prevValue => !prevValue);
    } catch (error) {
      console.error(error);
    }
  };
  const setFormStageValueInput = (e, key, index) => {
    let obj = JSON.parse(JSON.stringify(formDynamicProps));
    obj[index][key] = e.target.value;
    setFormDynamicProps(obj);
  };
  const setFormArrayValueInput = (e, name, value, key, index) => {
    e.preventDefault();
    let formObj = JSON.parse(JSON.stringify(entityObject));
    if (value.trim()) {
      if (formObj[name]) {
        formObj[name].push(value.trim());
      } else {
        formObj[name] = [];
        formObj[name].push(value.trim());
      }
    }
    setEntityObject(formObj);

    let dynamicPropObj = JSON.parse(JSON.stringify(formDynamicProps));
    dynamicPropObj[index][key] = "";
    setFormDynamicProps(dynamicPropObj);
  };
  const setArrayValue = (e, name, key, value, index, propIndex, item) => {
    e.preventDefault();
    let propName = name.split('-')[0];
    let formObj = JSON.parse(JSON.stringify(entityObject));
    if (value.trim()) {
      if (formObj[key][index].dependentPropValues) {
        formObj[key][index].dependentPropValues.push(value.trim());
      } else {
        formObj[key][index][propName] = [];
        formObj[key][index][propName].push(value.trim());
      }
    }
    setEntityObject(formObj);

    let dynamicPropObj = JSON.parse(JSON.stringify(formDynamicProps));

    dynamicPropObj[propIndex][item] = "";
    setFormDynamicProps(dynamicPropObj);
  };
  const setArrayValueForEnum = (e, name, key, value, index,propIndex,item) => {
    e.preventDefault();
    let propName = name.split('-')[0];
    let formObj = JSON.parse(JSON.stringify(entityObject));
    if (value.trim()) {
      if (formObj[key][index].dependentEnums) {
        formObj[key][index].dependentEnums.push(value.trim());
      } else {
        formObj[key][index][propName] = [];
        formObj[key][index][propName].push(value.trim());
      }
    }
    setEntityObject(formObj);

    let dynamicPropObj = JSON.parse(JSON.stringify(formDynamicProps));
    dynamicPropObj[propIndex][item] = "";
    setFormDynamicProps(dynamicPropObj);
  };
  const removeFormArrayValueInput = (e, key, arrRemoveIndex) => {
    let obj = JSON.parse(JSON.stringify(entityObject));
    obj[key].splice(arrRemoveIndex, 1);
    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  };
  const setFormArrayValueInputForProp = (e, name, value, key,index,propIndex) => {
    e.preventDefault();
    let formObj = JSON.parse(JSON.stringify(entityObject));
    if (!e.target.value) {
      if (name.split('-')[0] === 'dependentProp') {
        formObj[key][index].dependentProp = e.target.value;
        let successNode = document.getElementById("success-"+[key][index].dependentProp);
        if (successNode) {
          successNode.style.display = "none";
        }
      } else if (name.split('-')[0] === 'dependentPropValue') {
        formObj[key][index].dependentPropValue = e.target.value;
        let successNode = document.getElementById("success-"+[key][index].dependentProp);
        if (successNode) {
          successNode.style.display = "none";
        }
      }
    } else if (value.trim()) {
      if (formObj[key]) {
        if (name.split('-')[0] === 'dependentProp') {
          formObj[key][index].dependentProp = e.target.value;
        } else if (name.split('-')[0] === 'dependentPropValue') {
          formObj[key][index].dependentPropValue = e.target.value;
        }
      } else {
        formObj[key] = [];
        formObj[key].push(formObj[name] = e.target.value);
      }
    }
    setEntityObject(formObj);
  };
  const setFormArrayValueInputForEnum = (e, name, value, key,index,propIndex) => {
    e.preventDefault();
    let formObj = JSON.parse(JSON.stringify(entityObject));
    if (!e.target.value) {
      if (name.split('-')[0] === 'nameOfProp') {
        formObj[key][propIndex].query[index].nameOfProp = e.target.value;
        let successNode = document.getElementById("success-"+[key][propIndex].query[index].nameOfProp);
        if (successNode) {
          successNode.style.display = "none";
        }
      } else if (name.split('-')[0] === 'valueOfProp') {
        formObj[key][propIndex].query[index].valueOfProp = e.target.value;
        let successNode = document.getElementById("success-"+[key][propIndex].query[index].valueOfProp);
        if (successNode) {
          successNode.style.display = "none";
        }
      }
    } else if (value.trim()) {
      if (formObj[key]) {
        if (name.split('-')[0] === 'nameOfProp') {
          formObj[key][propIndex].query[index].nameOfProp = e.target.value;
        } else if (name.split('-')[0] === 'valueOfProp') {
          formObj[key][propIndex].query[index].valueOfProp = e.target.value;
        }
      } else {
        formObj[key] = [];
        formObj[key].push(formObj[name] = e.target.value);
      }
    }
    setEntityObject(formObj);
  };
  const removeFormQueryValueInput = (e, key, arrRemoveIndex,index) => {
    let obj = JSON.parse(JSON.stringify(entityObject));
    obj[key][arrRemoveIndex].query.splice(index, 1);
    setEntityObject(obj);
  };
  const removeFormArrayValueInputFromProp = (e, key, arrRemoveIndex,index) => {
    let obj = JSON.parse(JSON.stringify(entityObject));
    obj[key][index].dependentPropValues.splice(arrRemoveIndex, 1);
    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  };
  const removeFormArrayValueInputFromEnum = (e, key, arrRemoveIndex,index) => {
    let obj = JSON.parse(JSON.stringify(entityObject));
    obj[key][index].dependentEnums.splice(arrRemoveIndex, 1);
    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  };
  const addObject = (e, key) => {
    e.preventDefault();
    let obj = JSON.parse(JSON.stringify(entityObject));
    if (operation === 'Edit') {
      obj[key].push({});
    } else {
      obj[key]=[{}];
    }
    setEntityObject(obj);

  };
  const addObjectForEnum = (e, key, index) => {
    e.preventDefault();
    let obj = JSON.parse(JSON.stringify(entityObject));
    if (operation === 'Edit') {
      if (obj[key][index].query) {
        obj[key][index].query.push({});
      } else {
        obj[key][index].query = [{}];
      }
    } else {
      obj[key][index].query = [{}];
    }
    setEntityObject(obj);

  };
  const setFormStageValueInputForDependantProps = (e, key, index,value) => {
    let obj = JSON.parse(JSON.stringify(formDynamicProps));
    obj[index][key] = e.target.value;
    setFormDynamicProps(obj);
  };

  /*
    Modification: PH_TN_T1439 Added function to handle update/add/remove of employee debit details
    By: Devang
    Date: 23/01/2024
  */

  /*
    By: Prasannadatta Kawadkar
    On: 17 May 2024
    Modification: In Acknowledge request, one can not add more than one branch, employee, etc.
  */

  const handleChangeEmployeeDebitDetails = (employee, fieldName, e) => {
    let obj = deepCopy(entityObject);
    if (!obj[fieldName]) {
      obj[fieldName] = [];
    }

    // Check if the object is already present in the array
    const index = obj[fieldName].findIndex(empObj => empObj.uuid === employee.uuid);
    if (index === -1) {
      // Object not present, add it to the array
      if(fieldName === "vendorID") {
        if(!obj[fieldName].length) {
          obj[fieldName].push({
            uuid: employee.uuid,
            dynamicProperties_displayName: employee.dynamicProperties_displayName,
          });
        } else {
          toastr.warning("Can't select more than one vendor");
        }
      } else if (obj.displayName === 'Acknowledge Request') {
        if(!obj[fieldName].length) {
          obj[fieldName].push({
            uuid: employee.uuid,
            code: employee.code,
            displayName: employee.displayName,
          });
        } else {
          toastr.warning("Can't select more than one vendor");
        }
      } else {
        obj[fieldName].push({
          uuid: employee.uuid,
          code: employee.code,
          displayName: employee.displayName,
        });
      }

    } else {
      // Object already present, remove it from the array
      obj[fieldName].splice(index, 1);
    }

    // Set the updated array
    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  }
  const removeEmployeeDebitDetails = (isDisabled, employee, fieldName, e) => {
    if(isDisabled === true) {
      return
    }
    let obj = deepCopy(entityObject);
    if (!obj[fieldName]) {
      obj[fieldName] = [];
    }

    // Check if the object is already present in the array
    const index = obj[fieldName].findIndex(empObj => empObj.uuid === employee.uuid);

    if (index > -1) {
      // Object already present, remove it from the array
      obj[fieldName].splice(index, 1);
    }

    // Set the updated array
    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  }
  const updateEmployeeDebitDetails = (e, fieldName, employee) => {
    let obj = deepCopy(entityObject);

    // Check if the object is already present in the array
    const index = obj[fieldName].findIndex(empObj => empObj.uuid === employee.uuid);

    obj[fieldName][index][e.target.name] = e.target.value;

    // Set the updated array
    setEntityObject(obj);
    setIsFormUpdated(!isFormUpdated);
  }

  const isAllowedFileType = (f) => {
    let fileName = f.name;
    let extension = fileName.split('.').pop();
    if (filetypes.includes(extension)) {
      return true;
    }
    return false;

  };
  const uploadFile = async (e, fieldName, props, propIndex) => { // uploading images
    console.log("HERE 712",fieldName, props, propIndex);
    const fileTypeError = await isAllowedFileType(e.target.files[0]);
    if (!fileTypeError) {
      toastr.warning("This file cannot be uploaded either because this is not a supported file type. Please exclude such files and try again.");
      return;
    }
    const file = {
      name: e.target.files[0].name,
      size: e.target.files[0].size,
      type: e.target.files[0].type,
      value: e.target.files[0].name
    };

    try {
      let payload = {
        file,
        vendorKey: fieldName,
        interactionID: "entity"
      };
      let fileDetails;
      await axios.post(`${CONSTANTS.API_URL}/api/v1/file/getSignedUrl`, payload).then(async res => {
        if (res && res.data) {
          fileDetails = res.data;
          try {
            //  Save File on S3
            const opts = {
              headers: {
                name: 'Content-Type',
                value: 'multipart/form-data'
              }
            };

            let fileData = e.target.files[0];
            let fileExtension = e.target.files[0].name.split('.').pop();
            if (imageTypeExtension.includes(fileExtension)) {
              fileData = await getCompressedImageFile(e.target);
            }

            const fileUpload = await axios.put(fileDetails.signedURL, fileData, opts);

            let processTimeStarted;
            let optsData = {
              onUploadProgress(progressEvent) {
                let processPercentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                if (processPercentCompleted  >= 0 && !processTimeStarted) {
                  processTimeStarted = new Date();

                } else if (processPercentCompleted > 0 && processTimeStarted) {
                  let timeElapsed = (new Date()) - processTimeStarted; // timeStarted is a Date Object
                  let uploadSpeed = progressEvent.loaded / (timeElapsed/1000); // Upload speed in second

                  let time = (progressEvent.total - progressEvent.loaded) / uploadSpeed;
                  let processTimeToUpload = Math.round(time);
                  if (processTimeToUpload >= 60) {
                    processTimeToUpload = Math.round(processTimeToUpload / 60);
                  } else {
                    // that.state.processTimeValue = "seconds";
                  }
                }
              }
            };

            if (fileExtension === 'pdf'){
              let process = await axios.put(`${CONSTANTS.API_URL}/api/file/processMultiple/pdf`, {
                fileName: file.name,
                fileID: fileDetails.uuid
              }, optsData);
            }
            if (fileExtension === 'ppt' || fileExtension === 'pptx'){
              let process = await axios.put(`${CONSTANTS.API_URL}/api/file/processMultiple`, {
                fileName: file.name,
                fileID: fileDetails.uuid
              }, optsData);
            }
            if (fileExtension === 'docx'){
              let process = await axios.put(`${CONSTANTS.API_URL}/api/file/processMultiple/doc`, {
                fileName: file.name,
                fileID: fileDetails.uuid
              }, optsData);
            }

            let obj = JSON.parse(JSON.stringify(entityObject));
            obj[ fieldName ] = fileDetails.uuid;
            setEntityObject(obj);

            await setIsFormUpdated(!isFormUpdated);
          } catch (HTTPException) {
            let payload = {
              apiURL:(HTTPException && HTTPException.config && HTTPException.config.url) ? HTTPException.config.url : 0,
              errorObject:HTTPException,
              statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
              errorMessage:HTTPException.message,
              functionName:"uploadFile",
              fileName:"coreEntityDynamicForm",
              pageName: "coreEntityDynamicForm",
              userID: userInfo.uuid,
              userName:userInfo.displayName
            };
            await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
            return HTTPException;
          }
        }
      });
    } catch (HTTPException) {
      let parameter = {
        file
      };
      let payload = {
        apiURL:(HTTPException && HTTPException.config && HTTPException.config.url) ? HTTPException.config.url : 0,
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"uploadFile",
        fileName:"coreEntityDynamicForm",
        pageName: "coreEntityDynamicForm",
        payload: parameter,
        userID: userInfo.uuid,
        userName:userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  };
  const deleteUploadedFile = async (e, fieldName) => { // deleting uploaded image
    let obj = JSON.parse(JSON.stringify(entityObject));
    obj[ fieldName ] = "";
    setEntityObject(obj);
  };
  const createCoreEntity = async (payload) => {
    console.log(entityObject, 'Yo this is the entityObject');
    console.log(payload, 'Yo this is the payload');
    try {
      await setIsFormSubmitted(true);
      if (entityObject.allCreatedTasks && entityObject.allCreatedTasks[0] && entityObject.allCreatedTasks[0].uuid) {
        payload.coreEntityID = entityObject.allCreatedTasks[0].coreEntityID;
        payload.coreEntity = entityObject.allCreatedTasks[0].coreEntity;
        if(typeRequest === "Repair Request") {
          payload.typeRequest = "Repair Request"
        }
        if(entityObject.typeRequest === "Repair Request") {
          await preSaveFormInput();
        }
        if(entityObject.displayName === "Submit Bill") {
          if(entityObject.totalAmount > entityObject.vendorInitialEstimateAmount) {
            payload.isApprovalRequired = "True";
          } else {
            payload.isApprovalRequired = "False";
          }
        }
        let Response = await axios.put(`${CONSTANTS.API_URL}/api/v1/markEntityTaskAsComplete/${entityObject.allCreatedTasks[0].uuid}`, payload);
        if (Response.data.messageResponses && Response.data.messageResponses.length) {
          await Promise.all(Response.data.messageResponses.map(async (messageObj) => {
            await axios.post(`${CONSTANTS.API_URL}/api/v1/whatsappText`, {
              number: messageObj.whatsappNumber ? messageObj.whatsappNumber : messageObj.mobile,
              message: messageObj.message
            });
            if (messageObj.sendTo) {
              let messageNotification = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, messageObj);
              toastr.info(messageObj.message);
            } else {
              toastr.info(messageObj.message);
            }
          }));
        }
      }
    } catch (HTTPException) {
      let parameter = {
        file
      };
      let payload = {
        apiURL:(HTTPException && HTTPException.config && HTTPException.config.url) ? HTTPException.config.url : 0,
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"createCoreEntity",
        fileName:"coreEntityDynamicForm",
        pageName: "coreEntityDynamicForm",
        payload: parameter,
        userID: userInfo.uuid,
        userName:userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  };
  const updateCoreEntity = async (payload) => {
    console.log(entityObject, 'Yo this is the entityObject');
    console.log(payload, 'Yo this is the payload     jkgjhfjhfhgfg');
    try {
      if (payload) {
        payload.dynamicProperties_Flag = "Waiting For Allocation"
      }
      await setIsFormSubmitted(true);
      if (entityObject.allCreatedTasks && entityObject.allCreatedTasks[0] && entityObject.allCreatedTasks[0].uuid) {
        let Response = await axios.put(`${CONSTANTS.API_URL}/api/v1/markEntityTaskAsComplete/${entityObject.allCreatedTasks[0].uuid}`, payload);
        if (Response.data.messageResponses && Response.data.messageResponses.length) {
          await Promise.all(Response.data.messageResponses.map(async (messageObj) => {
            await axios.post(`${CONSTANTS.API_URL}/api/v1/whatsappText`, {
              number: messageObj.whatsappNumber ? messageObj.whatsappNumber : messageObj.mobile,
              message: messageObj.message
            });
            if (messageObj.sendTo) {
              let messageNotification = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, messageObj);
              toastr.info(messageObj.message);
            } else {
              toastr.success(messageObj.message);
            }
          }));
        }
      }
    } catch (HTTPException) {
      let parameter = {
        file
      };
      let payload = {
        apiURL:(HTTPException && HTTPException.config && HTTPException.config.url) ? HTTPException.config.url : 0,
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"updateCoreEntity",
        fileName:"coreEntityDynamicForm",
        pageName: "coreEntityDynamicForm",
        payload: parameter,
        userID: userInfo.uuid,
        userName:userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  };

  /*
    modified : Ashutosh G
    modified : 23/10/2023
    modification : getting options for requestForSelection depents on given entity
  */
  async function getRequestedForOptions(entity) {
    try {
      if (!entity || !Object.keys(urlObject).includes(entity.toLowerCase()))
        return;

      entity = entity.toLowerCase();

      let response = await callAPI(`get`, urlObject[entity].url, urlObject[entity].parameter);
      setEmployeeList(response.data);
    } catch (HTTPException) {
      console.log(HTTPException, 'HTTPExceptionHTTPException');
      let payload = {
        apiURL:HTTPException.config.url,
        errorObject:HTTPException,
        statusCode:HTTPException.response.status,
        errorMessage:HTTPException.message,
        functionName:"getRequestedForOptions",
        fileName: FILENAME,
        pageName: PAGENAME,
        userID: userInfo.uuid,
        userName:userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  }

  /*
    Modification: PH_TN_T1439 Added function get boolean of radio button values
    By: Devang
    Date: 24/01/2024
  */
  const getRadioValBoolean = (value) => {
    if (value.toLowerCase() === 'yes') return true;
    return false;
  };

  /*
    Modification: PH_TN_T1439 Brought fileViewer out of newPopupmodal in Fragment and added isViewedFrom prop in fileViewer
    By: Devang
    Date: 22/01/2024
  */
  return (
    <Fragment>
      <NewPopupModal isDataSaving={isDataSaving} operation={operation} isMobileView={isMobileView} classes="formModal" modalWidth={"90%"} modalDisplay={('show-formPopover overflow-hidden')} onClose={(e) => toggleFormPopover(e)} backgroundColor={backgroundColor ? backgroundColor: ""}>
        <div class="enquiryForm background-transparent h-full">
          <div class={`${isMobileView ? 'p-l-8 p-r-8' : 'p-l-30 p-r-30'} row p-t-10 p-b-10`} style="background:#f7f7f7;border-bottom:1px solid lightgrey">
            {!isMobileView && <div class="col-xs-9 col-sm-10">
              <span class="formHeader display-flex">
                <h4 class="m-t-5 m-b-0">{operation} {((operation !== 'CNG' && operation !== 'Cancellation' && operation !== 'Correction' && operation !== 'Accessories') ? ((operation.split(' ').length === 1) && entityName) : '')}</h4>
              </span>
            </div>}
            <div class={`${isMobileView ? 'col-xs-12 p-l-0 p-r-0' : 'col-xs-3 col-sm-2 p-r-2'} display-flex justify-flex-end align-center`}>
              {!isMobileView && isDataSaving ?
                (<div class="display-flex justify-flex-end align-center p-r-2">
                  <img src="/assets/images/inProgress.png" class="w-14px h-14px m-r-5" />
                  <span class="text-error">Saving...</span>
                </div>)
                :!isMobileView &&
                (<div class="display-flex justify-flex-end align-center p-r-2">
                  <img src="/assets/images/finish-flag.png" class="w-14px h-14px m-r-5" />
                  <span class="text-success">Saved</span><span class="visible-false">...</span>
                </div>)
              }
              {
                !isFormEditable && !isFormSubmitted && (
                  <button onClick={(e) => toggleEditForm(e)} class={`${isMobileView ? 'min-h-40px' : 'min-h-45'} primary-button m-l-10`}>Edit Form</button>
                )
              }
              {/*
                Modification: PH_TN_T1439 Added conditions to show appove/reject buttons according to button
                By: Devang
                Date: 27/01/2024
              */}
              {
                isFormEditable && (
                  masterTask && masterTask.containerType === 'Form and Approval' ?
                  <div class="flex-c-m">
                    <button id="approve-button" class={`${isMobileView ? 'min-h-40px' : 'min-h-45'} primary-button m-l-10`} onClick={e => submitForm(e, 'Approved')} disabled={((errorFields && errorFields.length > 0) || isFormSubmitted)} title={(errorFields && errorFields.length > 0) ? 'Oops! Seems like you have missed one or more items.' : ''}>Approve</button>
                    <button id="reject-button" class={`${isMobileView ? 'min-h-40px' : 'min-h-45'} secondary-button m-l-10`} onClick={e => submitForm(e, 'Rejected')} disabled={((errorFields && errorFields.length > 0) || isFormSubmitted)} title={(errorFields && errorFields.length > 0) ? 'Oops! Seems like you have missed one or more items.' : ''}>Reject</button>
                  </div>
                  :
                  <button onClick={(e) => submitForm(e)} class="primary-button m-l-10 min-h-45" disabled={((errorFields && errorFields.length > 0) || isFormSubmitted)} title={(errorFields && errorFields.length > 0) ? 'Oops! Seems like you have missed one or more items.' : ''}>{operation === 'Create' ? 'Create' : operation === 'Edit' ? 'Update' : 'Save'}</button>
                )
              }
              {/*
                  modified : Ashutosh G
                  modified : 20/12/2023
                  modification : list data reloaded when close the modal
              */}
              <button onClick={(e) => { toggleFormPopover(e); isListDataReLoad(prevValue => !prevValue) }} class={`${isMobileView ? 'min-h-40px' : 'min-h-45'} secondary-button m-l-10`}>Cancel</button>
            </div>
          </div>

          <div class="h-full">
            <form class="h-full">
              <div class="step-content formModalContainer" id="formModalContainer" style="display: block; box-shadow: none;">
                <div class="row">
                  {/*
                    Bug: CDMS_ADMIN_FIXES_51 Bug Functional	Admin tab - Request tile - Acknowledge Request form - REQUESTED FOR - Department , Employee - SELECTION FOR - Dropdown list	Dropdownlist is incorrect for the mentioned scenario
                    Modification: Added conditions for isMapperSelect
                    By: Devang
                    Date: 01/02/2024
                  */}
                  {/*
                      BY: Prasannadatta Kawadkar
                      ON: 20 May 2024
                      Modification: added dependentPropObjectKey in the below logic and made necessary changes
                  */}
                  {
                    dynamicProps.length > 0 && dynamicProps.map((dynamicProp, propIndex) => (
                      <div class={`${isMobileView ? 'm-t-10 p-l-0 p-r-0' : 'm-t-15'} col-xs-12 col-sm-12 col-md-12 col-lg-12`} style={`${((dynamicProp.formType !== "select" && dynamicProp.formType !== "radio" && dynamicProp.formType !== "checkbox" && !dynamicProp.dependentProp) ||
                        ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && !dynamicProp.dependentPropObjectKey && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)) )) ||
                        ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && dynamicProp.dependentPropObjectKey && ((typeof entityObject[dynamicProp.dependentProp] === 'object') && entityObject[dynamicProp.dependentProp][dynamicProp.dependentPropObjectKey] === dynamicProp.dependentPropValue) || (Array.isArray(entityObject[dynamicProp.dependentProp]) && entityObject[dynamicProp.dependentProp].find((obj) => { return obj[dynamicProp.dependentPropObjectKey] === dynamicProp.dependentPropValue})) ) ||
                        ((dynamicProp.formType === "radio" || dynamicProp.formType === "checkbox") && !dynamicProp.dependentProp) ||
                        (((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && (dynamicProp.typeofField !== "Array" && !dynamicProp.dependentProp))) ||
                        (((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && (dynamicProp.typeofField === "Array" && !dynamicProp.dependentProp))) ||
                        ((dynamicProp.formType === "date" || dynamicProp.formType === "time") && !dynamicProp.dependentProp) ||
                        ((dynamicProp.formType === "file") && (!dynamicProp.dependentProp && (!entityObject[dynamicProp.name] || (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA")))) ||
                        (dynamicProp.formType === "file" && !dynamicProp.dependentProp && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA")) ||
                        ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.dependentProp) ||
                        ((dynamicProp.formType === "radio" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                        ((dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                        ((dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                        (((dynamicProp.name === "area" || dynamicProp.name === "companyArea") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0))) ||
                        (((dynamicProp.name === "gstState") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.isMapperSelect) ||
                        (((dynamicProp.formType === "select" && (dynamicProp.dependentProp && dynamicProp.dependentPropValue) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) && !dynamicProp.isMapperSelect) ||
                        (((dynamicProp.formType === "select" && !dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) && !dynamicProp.isMapperSelect) ||
                        ((dynamicProp.formType === "select" && dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || ((entityObject[dynamicProp.dependentProp] && !dynamicProp.isMapperSelect) && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) || (dynamicProp.dependentPropValues && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))))))) ||
                        (((dynamicProp.formType === "select" && (dynamicProp.dependentEnum && dynamicProp.dependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (dynamicProp.dependentEnum.findIndex((d) => d.isSelected) > -1) && !dynamicProp.dependentProp) && !dynamicProp.isMapperSelect) ||
                        (((dynamicProp.formType === "select" && (dynamicProp.multipleDependentEnum && dynamicProp.multipleDependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (getFilteredList(dynamicProp.multipleDependentEnum).length === 1) && !dynamicProp.dependentProp) && !dynamicProp.isMapperSelect) ||
                        ((dynamicProp.formType === "select" && (dynamicProp.dependentProp && dynamicProp.dependentPropValue)) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && dynamicProp.isMapperSelect) ||
                        // (dynamicProp.formType === "select" && dynamicProp.isMapperSelect) ||
                        (dynamicProp.formType !== "select" && dynamicProp.dependentProp && dynamicProp.dependentPropValue && !dynamicProp.dependentPropObjectKey && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                        (dynamicProp.formType !== "select" && dynamicProp.dependentProp && !dynamicProp.dependentPropValue && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item))))) ||
                        (dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (!entityObject[dynamicProp.name] || (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA")) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                        (dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                        (dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && ((entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA") || !entityObject[dynamicProp.name]) && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item))))) ||
                        (dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item))))) ||
                        ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && !dynamicProp.dependentPropObjectKey && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)))) ||
                        ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp]))) ||
                        ((dynamicProp.formType === "date" || dynamicProp.formType === "time") && dynamicProp.dependentProp && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)) || (dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp]))))) ||
                        ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)))) ||
                        (((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])))) ||
                        ((dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)))) ||
                        ((dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])))) ? '' : 'display: none;'}`}>
                        <div class={`input-box ${(errorFields && errorFields.length > 0 && (errorFields.findIndex(item => item.fieldName === dynamicProp.name) > -1)) && 'error-div'}`} id={`${dynamicProp.name}-input-box`}>
                          {(dynamicProp.name !== 'multipleDependentProps') && (dynamicProp.name !== 'multipleDependentEnum') && (
                            <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName}
                              <span style={entityObject[dynamicProp.name] ? "display: none": "display:inline"}  id={`required-`+dynamicProp.name} class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span>
                              <span style={entityObject[dynamicProp.name] ? "display: inline": "display:none"} id={`success-`+dynamicProp.name}> <svg fill="green" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="15" height="15"><path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/></svg></span>
                              {/*
                                Modification: PH_TN_T1439 Added question Mark UI to show helperText
                                By: Devang
                                Date: 23/01/2024
                              */}
                              <span style={dynamicProp.helperText ? "display: inline-flex": "display:none"} id={`helperText-`+dynamicProp.helperText}><QuestionMark masterClasses="flex-c-m m-l-4 cursor-help bo-cir bg-hov-gray" title={dynamicProp.helperText} width="16" height="16"/></span>
                            </p>
                          )}
                          {
                            ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") &&  (dynamicProp.typeofField !== "Array" && !dynamicProp.dependentProp)) && (
                              <div>
                                <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable ) ? true: false} onClick={(e) => inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] ? true : false} value={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name] : ''} onInput={(e) => setFormValueInput(e, dynamicProp.name)} onFocusout={(e) => saveFormInput(e, dynamicProp.name)} />
                              </div>
                            )
                          }
                          {
                            (dynamicProp.name === 'multipleDependentProps'|| dynamicProp.name ==='multipleDependentEnum') && (dynamicProp.formName === 'DynamicProperty'|| !dynamicProp.dependentProp) && (
                              <div class='display-flex'>
                                <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                <button class='m-l-auto m-r-1' onClick={(e) => addObject(e, dynamicProp.name)}>ADD</button>
                              </div>
                            )
                          }
                          {
                            ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && (dynamicProp.name !== 'multipleDependentProps') && (dynamicProp.name !== 'multipleDependentEnum')&& (dynamicProp.typeofField === "Array" && !dynamicProp.dependentProp)) && (
                              <div class="display-flex flex-direction-column">
                                <div class="display-flex">
                                  <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable ) ? true: false} onClick={(e) => inputClicked(e)} name={dynamicProp.name} id={`${dynamicProp.name}`} value={dynamicProp.stageKey} onInput={(e) => setFormStageValueInput(e, 'stageKey', propIndex)} type={dynamicProp.formType} />
                                  <button class="m-l-16" onClick={(e) => setFormArrayValueInput(e, dynamicProp.name, dynamicProp.stageKey, 'stageKey', propIndex)}>ADD</button>
                                </div>
                                <div class="display-flex flex-direction-column m-t-6">
                                  {entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length && entityObject[dynamicProp.name].map((value, index) => (
                                    <div class="display-flex flex-sb">
                                      <div>{value}</div>
                                      <div class="text-color-red cursor-pointer" onClick={(e) => removeFormArrayValueInput(e, dynamicProp.name, index)}>X</div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )
                          }
                          {
                            ((dynamicProp.formType === "text") && (dynamicProp.name === 'multipleDependentProps') && (dynamicProp.formName === 'DynamicProperty') && (dynamicProp.typeofField === "Array" && !dynamicProp.dependentProp)) && (
                              <div class=" m-t-10 display-flex flex-direction-column m-t-6" id={`${dynamicProp.name}-input-box`}>
                                {entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length && entityObject[dynamicProp.name].map((value, multipleDependentIndexndex) => (
                                  <div class="input-box display-flex flex-direction-column m-t-6">
                                    <div class="text-color-red cursor-pointer m-l-auto" onClick={(e) => removeFormArrayValueInput(e, dynamicProp.name, multipleDependentIndexndex)}>X</div>
                                    <div class="">
                                      <p class="formLabel fs-15 p-b-3">Dependent Prop <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                      <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} type="text" onClick={(e) => inputClicked(e)} name={`dependentProp-${multipleDependentIndexndex}`} id={`dependentProp-${multipleDependentIndexndex}`} value={value.dependentProp ? value.dependentProp:''} onInput={(e) => setFormArrayValueInputForProp(e, `dependentProp-${multipleDependentIndexndex}`, e.target.value,dynamicProp.name,multipleDependentIndexndex,propIndex)} />
                                    </div>
                                    <div class="">
                                      <p class="formLabel fs-15 p-b-3">Dependent Prop Values<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                      <div class="display-flex">
                                        <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => inputClicked(e)} name={`dependentPropValues-${multipleDependentIndexndex}`} id={`dependentPropValues-${multipleDependentIndexndex}`} value={dynamicProp[`multipleDependentKey-${multipleDependentIndexndex}`]} onInput={(e) => setFormStageValueInputForDependantProps(e, `multipleDependentKey-${multipleDependentIndexndex}`, propIndex,value.dependentPropValues)} type='text'/>
                                        <button class="m-l-16" onClick={(e) => setArrayValue(e, `dependentPropValues-${multipleDependentIndexndex}`, dynamicProp.name, dynamicProp[`multipleDependentKey-${multipleDependentIndexndex}`], multipleDependentIndexndex,propIndex,`multipleDependentKey-${multipleDependentIndexndex}`)}>ADD</button>
                                      </div>
                                      <div class="display-flex flex-direction-column m-t-6">
                                        {value.dependentPropValues && value.dependentPropValues.length && value.dependentPropValues.map((valueDependentProp, multipleDependentPropValuesIndex) => (
                                          <div class="display-flex flex-sb">
                                            <div>{valueDependentProp}</div>
                                            <div class="text-color-red cursor-pointer" onClick={(e) => removeFormArrayValueInputFromProp(e, dynamicProp.name, multipleDependentPropValuesIndex,multipleDependentIndexndex)}>X</div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                    <div class="">
                                      <p class="formLabel fs-15 p-b-3">Dependent Prop Value<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                      <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} type="text" onClick={(e) => inputClicked(e)} name={`dependentPropValue-${multipleDependentIndexndex}`} id={`dependentPropValue-${multipleDependentIndexndex}`} value={value.dependentPropValue ? value.dependentPropValue : ''} onInput={(e) => setFormArrayValueInputForProp(e, `dependentPropValue-${multipleDependentIndexndex}`, e.target.value,dynamicProp.name,multipleDependentIndexndex,propIndex)} />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )
                          }
                          {
                            ((dynamicProp.formType === "text") && (dynamicProp.name === 'multipleDependentEnum') && (dynamicProp.formName === 'DynamicProperty') && (dynamicProp.typeofField === "Array" && !dynamicProp.dependentProp)) && (
                              <div class="input-box m-t-10 display-flex flex-direction-column m-t-6" id={`${'query'}-input-box`}>
                                {entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length && entityObject[dynamicProp.name].map((value, multipleDependentIndexndex) => (
                                  <div class="input-box display-flex flex-direction-column m-t-6">
                                    <div class="text-color-red cursor-pointer m-l-auto" onClick={(e) => removeFormArrayValueInput(e, dynamicProp.name, multipleDependentIndexndex)}>X</div>
                                    <div class="m-t-10 display-flex flex-direction-column m-t-6" >
                                      <div class='display-flex'>
                                        <p class="formLabel fs-15 p-b-3">Query <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                        <button class='m-l-auto m-r-1' onClick={(e) => addObjectForEnum(e, dynamicProp.name,multipleDependentIndexndex)}>ADD</button>
                                      </div>
                                      {value.query && value.query.length && value.query.map((queryValue, queryIndex) => (
                                        <div class=" input-box display-flex flex-direction-column m-t-6">
                                          <div class="text-color-red cursor-pointer m-l-auto" onClick={(e) => removeFormQueryValueInput(e, dynamicProp.name, multipleDependentIndexndex,queryIndex)}>X</div>
                                          <div class="">
                                            <p class="formLabel fs-15 p-b-3">Name Of Prop<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                            <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} type="text" onClick={(e) => inputClicked(e)} name={`nameOfProp-${queryIndex}`} id={`nameOfProp-${multipleDependentIndexndex}-${queryIndex}`} value={queryValue.nameOfProp ? queryValue.nameOfProp:''} onInput={(e) => setFormArrayValueInputForEnum(e, `nameOfProp-${queryIndex}`, e.target.value,dynamicProp.name,queryIndex,multipleDependentIndexndex)} />
                                          </div>
                                          <div class="m-t-10">
                                            <p class="formLabel fs-15 p-b-3">Value Of Prop<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                            <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} type="text" onClick={(e) => inputClicked(e)} name={`valueOfProp-${queryIndex}`} id={`valueOfProp-${multipleDependentIndexndex}-${queryIndex}`} value={queryValue.valueOfProp ? queryValue.valueOfProp : ''} onInput={(e) => setFormArrayValueInputForEnum(e, `valueOfProp-${queryIndex}`, e.target.value,dynamicProp.name,queryIndex,multipleDependentIndexndex)} />
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <div class=" m-t-25">
                                      <p class="formLabel fs-15 p-b-3">Dependent Enums<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                      <div class="display-flex">
                                        <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => inputClicked(e)} name={`dependentEnums-${multipleDependentIndexndex}`} id={`dependentEnums-${multipleDependentIndexndex}`} value={dynamicProp[`dependentEnums-${multipleDependentIndexndex}`]} onInput={(e) => setFormStageValueInputForDependantProps(e, `dependentEnums-${multipleDependentIndexndex}`, propIndex,value.dependentEnums)} type='text'/>
                                        <button class="m-l-16" onClick={(e) => setArrayValueForEnum(e, `dependentEnums-${multipleDependentIndexndex}`, dynamicProp.name, dynamicProp[`dependentEnums-${multipleDependentIndexndex}`], multipleDependentIndexndex,propIndex,`dependentEnums-${multipleDependentIndexndex}`)}>ADD</button>
                                      </div>
                                      <div class="display-flex flex-direction-column m-t-6">
                                        {value.dependentEnums && value.dependentEnums.length && value.dependentEnums.map((valueDependentEnums, multipleDependentPropValuesIndex) => (
                                          <div class="display-flex flex-sb">
                                            <div>{valueDependentEnums}</div>
                                            <div class="text-color-red cursor-pointer" onClick={(e) => removeFormArrayValueInputFromEnum(e, dynamicProp.name, multipleDependentPropValuesIndex,multipleDependentIndexndex)}>X</div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>

                                ))}
                              </div>
                            )
                          }
                          {
                            (dynamicProp.formType === "date" || dynamicProp.formType === "time") && !dynamicProp.dependentProp && (
                              <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} maxlength={dynamicProp.max && dynamicProp.max} value={moment(new Date(entityObject[dynamicProp.name])).format('YYYY-MM-DD') || ''} onInput={(e) => setFormValueInput(e, dynamicProp.name)} onFocusout={(e) => saveFormInput(e, dynamicProp.name)} max={(dynamicProp.typeOfValidation && dynamicProp.typeOfValidation === 'futureDateDisabled') && futureDateDisabled}/>
                            )
                          }
                          {
                            (dynamicProp.formType === "file") && (!dynamicProp.dependentProp && (!entityObject[dynamicProp.name] || (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA"))) && (
                              <div>
                                <input class="uploadInputFile" autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}`} id={`${dynamicProp.name}`} type={dynamicProp.formType} value={entityObject[dynamicProp.name]} accept="image/*,.pdf" onChange={(e) => uploadFile(e, dynamicProp.name)}/>
                                <label class="p-t-b-5" for={`${dynamicProp.name}`}>
                                  <figure>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24" width="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z" /><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z" /></svg>
                                  </figure>
                                  <span>Choose a file</span>
                                </label>
                              </div>
                            )
                          }
                          {
                            dynamicProp.formType === "file" && !dynamicProp.dependentProp && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && (
                              <div class="uploaded-image">
                                <button type="button" id={`image-0-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, entityObject.uuid)}>View Uploaded File</button>
                                <p class="delete-file-icon" title="Delete file" onClick={(e) => deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                              </div>
                            )
                          }
                          {
                            (dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.dependentProp && (
                              <div class="display-flex p-r-10 align-center">
                                {/*
                                  Modification: PH_TN_T1439 Fixed chrome radio button selection issue
                                  By: Devang
                                  Date: 24/01/2024
                                */}
                                {/*<input
                                  key={`${dynamicProp.name}-yes`}
                                  autocomplete="off"
                                  disabled={(dynamicProp.isDisabled || !isFormEditable) ? true : false}
                                  required={dynamicProp.isRequired ? "true" : "false"}
                                  name={`${dynamicProp.name}`}
                                  id={`${dynamicProp.name}-yes`}
                                  type={dynamicProp.formType}
                                  checked={(entityObject[dynamicProp.name] === "yes" || entityObject[dynamicProp.name] === true) ? true : false}
                                  value="yes"
                                  onChange={(e) => saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)}
                                />
                                <label class="abc p-l-10 p-r-10" style="font-weight: 300;" for={`${dynamicProp.name}-yes`}>Yes</label>

                                <input
                                  key={`${dynamicProp.name}-no`}
                                  autocomplete="off"
                                  disabled={(dynamicProp.isDisabled || !isFormEditable) ? true : false}
                                  required={dynamicProp.isRequired ? "true" : "false"}
                                  name={`${dynamicProp.name}`}
                                  id={`${dynamicProp.name}-no`}
                                  type={dynamicProp.formType}
                                  checked={(entityObject[dynamicProp.name] === "no" || entityObject[dynamicProp.name] === false) ? true : false}
                                  value="no"
                                  onChange={(e) => saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)}
                                />
                                <label class="abc p-l-10 p-r-10" style="font-weight: 300;" for={`${dynamicProp.name}-no`}>No</label>*/}
                                {["Yes", "No"].map((enumVal) => (
                                  <Fragment>
                                    <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${propIndex}`} id={`${dynamicProp.name}-${propIndex}-${enumVal.toLowerCase()}`} type={dynamicProp.formType} defaultChecked={(entityObject[dynamicProp.name] === enumVal.toLowerCase() || entityObject[dynamicProp.name] === getRadioValBoolean(enumVal)) ? true : false} value={enumVal.toLowerCase()} onChange={(e) => saveFormInputRadio(e, dynamicProp.name, enumVal.toLowerCase())} />
                                    <label class='p-l-10 p-r-10' style='font-weight: 300;' for={`${dynamicProp.name}-${propIndex}-${enumVal.toLowerCase()}`}>{enumVal}</label>
                                  </Fragment>
                                ))}

                              </div>
                            )
                          }
                          {
                            (dynamicProp.formType === "radio" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                              <div class="display-flex align-center">
                                {/*
                                  Modification: PH_TN_T1439 Fixed chrome radio button selection issue
                                  By: Devang
                                  Date: 24/01/2024
                                */}
                                {
                                  dynamicProp.enum.map((enumVal) => (
                                    <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} defaultChecked={entityObject[dynamicProp.name] === enumVal} value={enumVal} onChange={(e) => saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                                  ))
                                }
                              </div>
                            )
                          }
                          {
                            (dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                              <div>
                                {
                                  dynamicProp.enum.map((enumVal) => (
                                    <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}-${enumVal}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} defaultChecked={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name].split(",").includes(enumVal) : ""} onChange={(e) =>  saveFormInputCheckbox(e, dynamicProp.name, enumVal)}  /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                                  ))
                                }
                              </div>
                            )
                          }
                          {
                            (dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && (
                              <div>
                                {
                                  dynamicProp.enum.map((enumVal) => (
                                    <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}-${enumVal}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} defaultChecked={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name].split(",").includes(enumVal) : ""} onChange={(e) =>  saveFormInputCheckbox(e, dynamicProp.name, enumVal)}  /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                                  ))
                                }
                              </div>
                            )
                          }
                          {
                            ((dynamicProp.name === "area" || dynamicProp.name === "companyArea") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (
                              <div>
                                <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  saveAreaFromForm(e, dynamicProp.name)} >
                                  {
                                    areaList && areaList.length > 0 && areaList.map((enumVal) => (
                                      <option selected={entityObject[dynamicProp.name] === enumVal.officeName} value={enumVal.officeName}>{enumVal.officeName}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            )
                          }
                          {/*
                            Bug: CDMS_ADMIN_FIXES_51 Bug Functional	Admin tab - Request tile - Acknowledge Request form - REQUESTED FOR - Department , Employee - SELECTION FOR - Dropdown list	Dropdownlist is incorrect for the mentioned scenario
                            Modification: Added condition for not isMapperSelect
                            By: Devang
                            Date: 01/02/2024
                          */}
                          {
                            ((dynamicProp.name === "gstState") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.isMapperSelect && (
                              <div>
                                <select disabled={(dynamicProp.isDisabled) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  saveFormInput(e, dynamicProp.name, "select", 0)} >
                                  <option value=''>Select State</option>
                                  {
                                    stateList && stateList.length > 0 && stateList.map((enumVal) => (
                                      <option selected={entityObject.hasOwnProperty(dynamicProp.name) ? (entityObject[dynamicProp.name] === enumVal.name) : (entityObject["dynamicProperties_" + dynamicProp.name] === enumVal.name)} value={enumVal.name}>{enumVal.name}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            )
                          }
                          {/*
                            Bug: CDMS_ADMIN_FIXES_51 Bug Functional	Admin tab - Request tile - Acknowledge Request form - REQUESTED FOR - Department , Employee - SELECTION FOR - Dropdown list	Dropdownlist is incorrect for the mentioned scenario
                            Modification: Commented below condition and added isMapperSelect condition
                            By: Devang
                            Date: 01/02/2024
                          */}
                          {/*
                              modified : Ashutosh G
                              modified : 23/10/2023
                              modification : added custom field beacause getting employee name form db
                          */}
                          {/*
                            ((dynamicProp.name === "dynamicProperties_requestedForSelection") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (
                              <div>
                                <select disabled={(dynamicProp.isDisabled) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  saveFormInput(e, dynamicProp.name, "select", 0)} >
                                  <option value=''>Select Request For</option>
                                  {
                                    employeeList && employeeList.length > 0 && employeeList.map((enumVal) => (
                                      <option selected={entityObject.hasOwnProperty(dynamicProp.uuid) ? (entityObject[dynamicProp.uuid] === enumVal.uuid) : (entityObject["dynamicProperties_" + dynamicProp.uuid] === enumVal.uuid)} value={enumVal.uuid}>{enumVal.displayName}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            )
                          */}
                          {
                            /*dynamicProp.formType === "select" && dynamicProp.isMapperSelect &&*/
                            (dynamicProp.formType === "select" && (dynamicProp.dependentProp && dynamicProp.dependentPropValue)) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && dynamicProp.isMapperSelect && (
                              <MapperSelect
                                allDynamicProps={dynamicProps}
                                dynamicProp={dynamicProp}
                                entityObject={entityObject}
                                saveFormInput={saveFormInput}
                                isDisabled={(dynamicProp.isDisabled || !isFormEditable || (entityObject.status && entityObject.status.toLowerCase() === "completed")) ? true: false}
                                propIndex={propIndex}
                              />
                            )
                          }
                          {/*
                            Bug: CDMS_ADMIN_FIXES_51 Bug Functional	Admin tab - Request tile - Acknowledge Request form - REQUESTED FOR - Department , Employee - SELECTION FOR - Dropdown list	Dropdownlist is incorrect for the mentioned scenario
                            Modification: Added condition for not isMapperSelect
                            By: Devang
                            Date: 01/02/2024
                          */}
                          {
                            (dynamicProp.formType === "select" && (dynamicProp.dependentProp && dynamicProp.dependentPropValue) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && !dynamicProp.isMapperSelect && (
                              <div>
                                {
                                  (entityObject.typeRequest && entityObject.typeRequest === "Repair Request") &&
                                    <div class="display-flex">
                                      <input autocomplete="off" class=""  disabled={true} name={"dynamicProperties_typeOfRequest"} id={"dynamicProperties_typeOfRequest"} type={"text"} value={"Repair / Service Request Flow"} />
                                    </div>
                                }
                                {
                                  (entityObject.typeRequest !== "Repair Request") &&
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => saveFormInput(e, dynamicProp.name, "select", 0)} >
                                        <option value=''>Select {dynamicProp.displayName}</option>
                                        {
                                          dynamicProp.enum.map((enumVal) => (
                                            <option selected={entityObject[dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                          ))
                                        }
                                      </select>
                                    </div>
                                }
                              </div>
                            )
                          }
                          {/*
                            Bug: CDMS_ADMIN_FIXES_51 Bug Functional	Admin tab - Request tile - Acknowledge Request form - REQUESTED FOR - Department , Employee - SELECTION FOR - Dropdown list	Dropdownlist is incorrect for the mentioned scenario
                            Modification: Added condition for not isMapperSelect
                            By: Devang
                            Date: 01/02/2024
                          */}
                          {
                            (dynamicProp.formType === "select" && !dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && !dynamicProp.isMapperSelect && (
                              <div>
                                <div class="display-flex">
                                  <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => saveFormInput(e, dynamicProp.name, "select", 0)} >
                                    <option value=''>Select {dynamicProp.displayName}</option>
                                    {
                                      dynamicProp.enum.map((enumVal) => (
                                        <option selected={entityObject[dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                            )
                          }
                          {/*
                            Bug: CDMS_ADMIN_FIXES_51 Bug Functional	Admin tab - Request tile - Acknowledge Request form - REQUESTED FOR - Department , Employee - SELECTION FOR - Dropdown list	Dropdownlist is incorrect for the mentioned scenario
                            Modification: Added condition for not isMapperSelect
                            By: Devang
                            Date: 01/02/2024
                          */}
                          {
                            ((dynamicProp.formType === "select" && dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) || (dynamicProp.dependentPropValues && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))))))) && !dynamicProp.isMapperSelect && (
                              <div>
                                <div class="display-flex">
                                  <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => saveFormInput(e, dynamicProp.name, "select", 0)} >
                                    <option value=''>Select {dynamicProp.displayName}</option>
                                    {
                                      dynamicProp.enum.map((item) => (
                                        <option selected={entityObject[dynamicProp.name] === (dynamicProp.enumIDKey ? item[dynamicProp.enumIDKey] : item.uuid)} value={(dynamicProp.enumIDKey ? item[dynamicProp.enumIDKey] : item.uuid)}>{item[dynamicProp.enumDisplayKey]}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                            )
                          }
                          {/*
                            Bug: CDMS_ADMIN_FIXES_51 Bug Functional	Admin tab - Request tile - Acknowledge Request form - REQUESTED FOR - Department , Employee - SELECTION FOR - Dropdown list	Dropdownlist is incorrect for the mentioned scenario
                            Modification: Added condition for not isMapperSelect
                            By: Devang
                            Date: 01/02/2024
                          */}
                          {
                            (dynamicProp.formType === "select" && (dynamicProp.dependentEnum && dynamicProp.dependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (dynamicProp.dependentEnum.findIndex((d) => d.isSelected) > -1) && !dynamicProp.dependentProp && !dynamicProp.isMapperSelect && (
                              <div>
                                <div class="display-flex">
                                  <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => saveFormInput(e, dynamicProp.name)} >
                                    <option value=''>Select {dynamicProp.displayName}</option>
                                    {
                                      dynamicProp.dependentEnum[dynamicProp.dependentEnum.findIndex((d) => d.isSelected)].dependentEnums.map((enumVal) => (
                                        <option selected={entityObject[dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                            )
                          }
                          {/*
                            Bug: CDMS_ADMIN_FIXES_51 Bug Functional	Admin tab - Request tile - Acknowledge Request form - REQUESTED FOR - Department , Employee - SELECTION FOR - Dropdown list	Dropdownlist is incorrect for the mentioned scenario
                            Modification: Added condition for not isMapperSelect
                            By: Devang
                            Date: 01/02/2024
                          */}
                          {
                            (((dynamicProp.formType === "select" && (dynamicProp.multipleDependentEnum && dynamicProp.multipleDependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (getFilteredList(dynamicProp.multipleDependentEnum).length === 1) && !dynamicProp.dependentProp) && !dynamicProp.isMapperSelect &&
                              <div>
                                <div class="display-flex">
                                  <select disabled={(dynamicProp.isDisabled || (entityObject.status && entityObject.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => saveFormInput(e, dynamicProp.name)} >
                                    <option value=''>Select {dynamicProp.displayName}</option>
                                    {
                                      getFilteredList(dynamicProp.multipleDependentEnum).length > 0 && getFilteredList(dynamicProp.multipleDependentEnum)[0].dependentEnums.map((enumVal) => (
                                        <option selected={entityObject[dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                            )
                          }
                          {
                            dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (!entityObject[dynamicProp.name] || (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA")) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                              <div>
                                <input class="uploadInputFile" autocomplete="off" required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}`} id={`${dynamicProp.name}`} type={dynamicProp.formType} accept="image/*,.pdf" onChange={(e) => uploadFile(e, dynamicProp.name)}/>
                                <label class="p-t-b-5" for={`${dynamicProp.name}`}>
                                  <figure>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24" width="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z" /><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z" /></svg>
                                  </figure>
                                  <span>Choose a file</span>
                                </label>
                              </div>
                            )
                          }
                          {
                            dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                              <div class="uploaded-image">
                                <button type="button" id={`image-0-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, entityObject.uuid)}>View Uploaded File</button>
                                <p class="delete-file-icon" title="Delete file" onClick={(e) => deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                              </div>
                            )
                          }
                          {
                            dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && ((entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA") || !entityObject[dynamicProp.name]) && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))) && (
                              <div>
                                <input class="uploadInputFile" autocomplete="off" required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} accept="image/*,.pdf" onChange={(e) => uploadFile(e, dynamicProp.name)}/>
                                <label class="p-t-b-5" for={`${dynamicProp.name}`}>
                                  <figure>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24" width="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z" /><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z" /></svg>
                                  </figure>
                                  <span>Choose a file</span>
                                </label>
                              </div>
                            )
                          }

                          {
                            dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))) && (
                              <div class="uploaded-image">
                                <button type="button" id={`image-0-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, entityObject.uuid)}>View Uploaded File</button>
                                <p class="delete-file-icon" title="Delete file" onClick={(e) => deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                              </div>
                            )
                          }
                          {
                            (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && !dynamicProp.dependentPropObjectKey && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue))) && (
                              <div>
                                <input autocomplete="off" class=""  disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] ? true : false} value={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name] : ''} onInput={(e) => setFormValueInput(e, dynamicProp.name)}  onFocusout={(e) => saveFormInput(e, dynamicProp.name)} />
                              </div>
                            )
                          }
                          {
                            ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && dynamicProp.dependentPropObjectKey && ((typeof entityObject[dynamicProp.dependentProp] === 'object') && entityObject[dynamicProp.dependentProp][dynamicProp.dependentPropObjectKey] === dynamicProp.dependentPropValue) || (Array.isArray(entityObject[dynamicProp.dependentProp]) && entityObject[dynamicProp.dependentProp].find((obj) => { return obj[dynamicProp.dependentPropObjectKey] === dynamicProp.dependentPropValue})) ) && (
                              <div>
                                <input autocomplete="off" class=""  disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] ? true : false} value={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name] : ''} onInput={(e) => setFormValueInput(e, dynamicProp.name)}  onFocusout={(e) => saveFormInput(e, dynamicProp.name)} />
                              </div>
                            )
                          }
                          {
                            (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) && (
                              <div>
                                <input autocomplete="off" class=""  disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] ? true : false} value={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name] : ''} onInput={(e) => setFormValueInput(e, dynamicProp.name)}  onFocusout={(e) => saveFormInput(e, dynamicProp.name)} />
                              </div>
                            )
                          }
                          {
                            (dynamicProp.formType === "date" || dynamicProp.formType === "time") && dynamicProp.dependentProp && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)) || (dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])))) && (
                              <div>
                                <input autocomplete="off" required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} maxlength={dynamicProp.max && dynamicProp.max} value={moment(new Date(entityObject[dynamicProp.name])).format('YYYY-MM-DD') || ''} onChange={(e) => saveFormInput(e, dynamicProp.name)} max={(dynamicProp.typeOfValidation && dynamicProp.typeOfValidation === 'futureDateDisabled') && futureDateDisabled}/>
                              </div>
                            )
                          }
                          {
                            (dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue))) && (
                              <div class="display-flex p-r-10 align-center">
                                {/*
                                  Modification: PH_TN_T1439 Fixed chrome radio button selection issue
                                  By: Devang
                                  Date: 24/01/2024
                                */}
                                {/*<input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}`} id={`${dynamicProp.name}-yes`} type={dynamicProp.formType} checked={(entityObject[dynamicProp.name] === "yes" || entityObject[dynamicProp.name] === true) ? true : false} value="yes" onChange={(e) => saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-yes`}>Yes </label>
                                <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}`} id={`${dynamicProp.name}-no`} type={dynamicProp.formType} checked={(entityObject[dynamicProp.name] === "no" || entityObject[dynamicProp.name] === false) ? true : false} value="no" onChange={(e) => saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-no`}>No </label>*/}
                                {["Yes", "No"].map((enumVal) => (
                                  <Fragment>
                                    <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${propIndex}`} id={`${dynamicProp.name}-${propIndex}-${enumVal.toLowerCase()}`} type={dynamicProp.formType} defaultChecked={(entityObject[dynamicProp.name] === enumVal.toLowerCase() || entityObject[dynamicProp.name] === getRadioValBoolean(enumVal)) ? true : false} value={enumVal.toLowerCase()} onChange={(e) => saveFormInputRadio(e, dynamicProp.name, enumVal.toLowerCase())} />
                                    <label class='p-l-10 p-r-10' style='font-weight: 300;' for={`${dynamicProp.name}-${propIndex}-${enumVal.toLowerCase()}`}>{enumVal}</label>
                                  </Fragment>
                                ))}
                              </div>
                            )
                          }
                          {
                            ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp]))) && (
                              <div class="display-flex p-r-10 align-center">
                                {/*
                                  Modification: PH_TN_T1439 Fixed chrome radio button selection issue
                                  By: Devang
                                  Date: 24/01/2024
                                */}
                                {/*<input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}`} id={`${dynamicProp.name}-yes`} type={dynamicProp.formType} checked={(entityObject[dynamicProp.name] === "yes" || entityObject[dynamicProp.name] === true) ? true : false} value="yes" onChange={(e) => saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-yes`}>Yes </label>
                                <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}`} id={`${dynamicProp.name}-no`} type={dynamicProp.formType} checked={(entityObject[dynamicProp.name] === "no" || entityObject[dynamicProp.name] === false) ? true : false} value="no" onChange={(e) => saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-no`}>No </label>*/}
                                {["Yes", "No"].map((enumVal) => (
                                  <Fragment>
                                    <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${propIndex}`} id={`${dynamicProp.name}-${propIndex}-${enumVal.toLowerCase()}`} type={dynamicProp.formType} defaultChecked={(entityObject[dynamicProp.name] === enumVal.toLowerCase() || entityObject[dynamicProp.name] === getRadioValBoolean(enumVal)) ? true : false} value={enumVal.toLowerCase()} onChange={(e) => saveFormInputRadio(e, dynamicProp.name, enumVal.toLowerCase())} />
                                    <label class='p-l-10 p-r-10' style='font-weight: 300;' for={`${dynamicProp.name}-${propIndex}-${enumVal.toLowerCase()}`}>{enumVal}</label>
                                  </Fragment>
                                ))}
                              </div>
                            )
                          }
                          {
                            (dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue))) && (
                              <div class="display-flex formRadioButtons">
                                {/*
                                  Modification: PH_TN_T1439 Fixed chrome radio button selection issue
                                  By: Devang
                                  Date: 24/01/2024
                                */}
                                {
                                  dynamicProp.enum.map((enumVal) => (
                                    <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} defaultChecked={entityObject[dynamicProp.name] === enumVal} value={enumVal} onChange={(e) => saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                                  ))
                                }
                              </div>
                            )
                          }
                          {
                            (dynamicProp.name === 'dynamicProperties_noOfDoublePayments') && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length > 0) && Array(Number(entityObject[dynamicProp.name])).fill(1).map((item, index) => ((
                              <div class="input-box m-t-10" id={`${dynamicProp.name}-input-box`}>
                                <div>
                                  <p class="formLabel fs-15 p-b-3">Wrong Transaction ID {index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  <div class="display-flex">
                                    <input autocomplete="off" class="" onClick={(e) => inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`dynamicProperties_wrongTransactionID${index + 1}`} id={`dynamicProperties_wrongTransactionID${index + 1}`} type="text" checked={entityObject[`dynamicProperties_wrongTransactionID${index + 1}`] ? true : false} value={entityObject[`dynamicProperties_wrongTransactionID${index + 1}`] ? entityObject[`dynamicProperties_wrongTransactionID${index + 1}`] : ''} onInput={(e) => setFormValueInput(e, `dynamicProperties_wrongTransactionID${index + 1}`)}  onFocusout={(e) => saveFormInput(e, `dynamicProperties_wrongTransactionID${index + 1}`)} />
                                  </div>
                                </div>
                                <div>
                                  <p class="formLabel fs-15 p-b-3">Wrong Transaction Amount {index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  <div class="display-flex">
                                    <input autocomplete="off" class="" onClick={(e) => inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`dynamicProperties_wrongTransactionAmount${index + 1}`} id={`dynamicProperties_wrongTransactionAmount${index + 1}`} type="number" checked={entityObject[`dynamicProperties_wrongTransactionAmount${index + 1}`] ? true : false} value={entityObject[`dynamicProperties_wrongTransactionAmount${index + 1}`] ? entityObject[`dynamicProperties_wrongTransactionAmount${index + 1}`] : ''} onInput={(e) => setFormValueInput(e, `dynamicProperties_wrongTransactionAmount${index + 1}`)}  onFocusout={(e) => saveFormInput(e, `dynamicProperties_wrongTransactionAmount${index + 1}`)} />
                                  </div>
                                </div>
                                <div>
                                  <p class="formLabel fs-15 p-b-3">Wrong Transaction Date {index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  <div class="display-flex">
                                    <input autocomplete="off" class="" onClick={(e) => inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`dynamicProperties_wrongTransactionDate${index + 1}`} id={`dynamicProperties_wrongTransactionDate${index + 1}`} type="date" checked={entityObject[`dynamicProperties_wrongTransactionDate${index + 1}`] ? true : false} value={entityObject[`dynamicProperties_wrongTransactionDate${index + 1}`] ? entityObject[`dynamicProperties_wrongTransactionDate${index + 1}`] : ''} onInput={(e) => setFormValueInput(e, `dynamicProperties_wrongTransactionDate${index + 1}`)}  onFocusout={(e) => saveFormInput(e, `dynamicProperties_wrongTransactionDate${index + 1}`)} />
                                  </div>
                                </div>
                              </div>
                            )))
                          }
                          {
                            (dynamicProp.name === 'noOfConditions') && (dynamicProp.formName === 'ConfigurationRule') && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length > 0) && Array(Number(entityObject[dynamicProp.name])).fill(1).map((item, index) => ((
                              <div class="input-box m-t-10" id={`${dynamicProp.name}-input-box`}>
                                <div>
                                  <p class="formLabel fs-15 p-b-3">Triggered By Field{index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  <div class="display-flex">
                                    <input autocomplete="off" class="" onClick={(e) => inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`triggeredByField${index + 1}`} id={`triggeredByField${index + 1}`} type="text" checked={entityObject[`triggeredByField${index + 1}`] ? true : false} value={entityObject[`triggeredByField${index + 1}`] ? entityObject[`triggeredByField${index + 1}`] : ''} onInput={(e) => setFormValueInput(e, `triggeredByField${index + 1}`)}  onFocusout={(e) => saveFormInput(e, `triggeredByField${index + 1}`)} />
                                  </div>
                                </div>
                                <div>
                                  <p class="formLabel fs-15 p-b-3">Triggered By Field Value {index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  <div class="display-flex">
                                    <input autocomplete="off" class="" onClick={(e) => inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`triggeredByFieldValue${index + 1}`} id={`triggeredByFieldValue${index + 1}`} type="text" checked={entityObject[`triggeredByFieldValue${index + 1}`] ? true : false} value={entityObject[`triggeredByFieldValue${index + 1}`] ? entityObject[`triggeredByFieldValue${index + 1}`] : ''} onInput={(e) => setFormValueInput(e, `triggeredByFieldValue${index + 1}`)}  onFocusout={(e) => saveFormInput(e, `triggeredByFieldValue${index + 1}`)} />
                                  </div>
                                </div>
                                <div>
                                  <p class="formLabel fs-15 p-b-3">Operator{index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  <div class="display-flex">
                                    <input autocomplete="off" class="" onClick={(e) => inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`operator${index + 1}`} id={`operator${index + 1}`} type="text" checked={entityObject[`operator${index + 1}`] ? true : false} value={entityObject[`operator${index + 1}`] ? entityObject[`operator${index + 1}`] : ''} onInput={(e) => setFormValueInput(e, `operator${index + 1}`)}  onFocusout={(e) => saveFormInput(e, `operator${index + 1}`)} />
                                  </div>
                                </div>
                              </div>
                            )))
                          }
                          {
                            (dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) && (
                              <div class="display-flex formRadioButtons">
                                {/*
                                  Modification: PH_TN_T1439 Fixed chrome radio button selection issue
                                  By: Devang
                                  Date: 24/01/2024
                                */}
                                {
                                  dynamicProp.enum.map((enumVal) => (
                                    <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} defaultChecked={entityObject[dynamicProp.name] === enumVal} value={enumVal} onChange={(e) => saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                                  ))
                                }
                              </div>
                            )
                          }

                          {/*
                            modified : Ashutosh G
                            modified : 23/10/2023
                            modification : added catelogue element below
                          */}
                          {/*
                            modified : Ashutosh G
                            modified : 20/12/2023
                            modification : enabling InventoryAvailableBox for dynamicProperties_availableStockInfo and detemining editable or not on inventoryAvailableBoxEditableNames array and entityObject passed
                          */}
                          {/*
                            Bug: CDMS_ADMIN_FIXES_60 Functional Admin tab - Request tile - Issue Inventory form	If not clicked on Edit form button , it is allowing to edit the data
                            Modification: Added isDisabled as prop to send in the component to disable inputs & removed console
                            By: Devang
                            Date: 31/01/2024
                          */}
                          {
                            (dynamicProp.name === "dynamicProperties_customCatalogue" || dynamicProp.name === "dynamicProperties_availableStockInfo") &&
                              <>
                                <InventoryAvailableBox
                                  fieldName={dynamicProp.name}
                                  setCustomFiledValues={setSelectedCatelogueItems}
                                  isEditable={inventoryAvailableBoxEditableNames.includes(dynamicProp.name)}
                                  entityObject={entityObject}
                                  isDisabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false}
                                  destinationLocation={props.destinationLocation}
                                />
                              </>
                          }
                          {/*
                            Modification: PH_TN_T1439 Added V2 for employeeDebitNote to handle employee table updates
                            By: Devang
                            Date: 27/01/2024
                          */}
                          {
                            (["employeeDebit", "dynamicProperties_employeeDebit"].includes(dynamicProp.name)) &&
                              /*
                              <EmployeeDebitNote fieldName={dynamicProp.name} setCustomFiledValues={selectedEmployeeDebitNotes} changeEmpployeeDebitDetails={changeEmpployeeDebitDetails}/>
                              */
                              <EmployeeDebitNoteV2
                                isMobileView={isMobileView}
                                fieldName={dynamicProp.name}
                                entityObject={entityObject}
                                handleChange={handleChangeEmployeeDebitDetails}
                                updateValue={updateEmployeeDebitDetails}
                                removeValue={removeEmployeeDebitDetails}
                                isDisabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false}
                                isShowDebitFields={dynamicProp.taskID === '1f2a1d3b-b11b-45d8-be90-4e5d8c90ecb4'}
                              />
                          }
                          {/*
                            Modification:- Search vendor in vendor selection.(Admin Sheet changes)
                            By:- Rajkumar Pradhan
                            On:- 17-05-2024
                          */}
                          {
                            (["vendorID","dynamicProperties_requestedForSelection","dynamicProperties_requestedForSelectionSub"].includes(dynamicProp.name)) && dynamicProp.formType === "table" &&
                              <CustomizeSelect
                                isMobileView={isMobileView}
                                dynamicProp={dynamicProp}
                                entityObject={entityObject}
                                handleChange={handleChangeEmployeeDebitDetails}
                                updateValue={updateEmployeeDebitDetails}
                                removeValue={removeEmployeeDebitDetails}
                                isDisabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false}
                                isShowDebitFields={dynamicProp.taskID === '1f2a1d3b-b11b-45d8-be90-4e5d8c90ecb4'}
                              />
                          }
                          {
                            (!dynamicProp.dependentProp || (dynamicProp.dependentProp && !dynamicProp.dependentPropObjectKey && ((entityObject['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[''+dynamicProp.dependentProp] && entityObject[''+dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))))) && (
                              <span id={`error-`+dynamicProp.name} class="fs-10" >{dynamicProp.description}</span>
                            )
                          }
                          {
                            (errorFields && errorFields.length > 0 && (errorFields.findIndex(item => item.fieldName === dynamicProp.name) > -1)) ? (
                              <p class="fs-10 text-color-red" >{errorFields.find(item => item.fieldName === dynamicProp.name).errorMessage}</p>
                            ) : (
                              <p class="fs-10 text-color-red" style="visibility: hidden;">Hidden Error</p>
                            )
                          }

                          {
                            dynamicProp.isAmount && entityObject[dynamicProp.name] &&
                            <span id={`amount-`+dynamicProp.name} class="fs-10 display-block" style="color:#4949ed;">{`INR ${inWords(entityObject[dynamicProp.name])}`}</span>
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </NewPopupModal>

      {isFileViewerOpen &&
        <FileViewer
          uploadBrowsedReferenceFile={uploadFile}
          closeFileViewer={closeFileViewer}
          toggleFileViewer={toggleFileViewer}
          isFileViewerOpen={isFileViewerOpen}
          uploadedfile={uploadedfile}
          fileViewerFor={fileViewerFor}
          contentDetails={{}}
          isDeleteHidden
          isUploadHidden
          isViewedFrom
        />
      }
    </Fragment>
  );
};

export default CoreEntityDynamicForm;
